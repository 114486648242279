import styled from "styled-components";

export const Container = styled.div`
  height: 2.5rem;
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: var(--second-auxiliary-color);
  border-radius: 2rem;
  margin: 1rem;
`;

export const ButtonLeft = styled.button`
  font-size: 2rem;
  height: 100%;
  width: fit-content;
  padding-left: 0.5rem;
  cursor: pointer;
  background-color: var(--second-auxiliary-color);
  color: var(--secondary-font-color);
  display: flex;
  align-items: center;
  border: 0;

  &:hover {
    opacity: 0.7;
  }
`;

export const ButtonRight = styled.button`
  font-size: 2rem;
  height: 100%;
  width: fit-content;
  cursor: pointer;
  padding-right: 0.5rem;
  background-color: var(--second-auxiliary-color);
  color: var(--secondary-font-color);
  display: flex;
  align-items: center;
  border: 0;

  &:hover {
    opacity: 0.7;
  }
`;

export const Counter = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--secondary-font-color);
  border-right: 1px solid var(--secondary-font-color);
  border-left: 1px solid var(--secondary-font-color);
  height: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
`;
