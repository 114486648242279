import { useState, useEffect } from "react";
import {
  Background,
  Form,
  Title,
  Button,
  CloseIcon,
  SenhaContainer,
  Copy,
  Copied,
} from "./style";
import InputLabel from "../../../InputLabel";
import api from "../../../../services/api";
import { Swal, Toast } from "../../../../services/SwalTemplates";
import { FaClipboard, FaClipboardCheck } from "react-icons/fa";

export default function ResetSenha({
  Show,
  setShow,
  Dados,
  setDados,
  setReload,
}) {
  const [Nome, setNome] = useState("");
  const [Email, setEmail] = useState("");
  const [Senha, setSenha] = useState(RandomNumber());
  const [IdUsuario, setIdUsuario] = useState(0);
  const [Copiado, setCopiado] = useState(false);

  useEffect(() => {
    if (Dados) {
      setIdUsuario(Dados?.id);
      setNome(Dados?.nome);
      setEmail(Dados?.email);
    }

    setSenha(RandomNumber());
  }, [Dados]);

  useEffect(() => {
    setTimeout(() => {
      setCopiado(false);
    }, 3000);
  }, [Copiado]);

  async function HandleSubmit(e) {
    e.preventDefault();

    Copiar();

    const data = {
      id: IdUsuario,
      senha: Senha,
    };

    api
      .patch(`/resetsenha`, { data })
      .then((response) => {
        Toast.fire({
          title: response?.data?.success,
          icon: "success",
        });
        setTimeout(() => {
          Close();
        }, 1000);
      })
      .catch((e) => {
        Swal.fire({ title: "Atenção!", text: "Error", icon: "error" });
      });
  }

  async function Close() {
    setDados("");
    setShow(false);
    setNome("");
    setEmail("");
    setIdUsuario(0);
    setCopiado(false);
    setReload((r) => !r);
  }

  function RandomNumber() {
    return Math.floor(1000 + Math.random() * 9000);
  }

  function Copiar() {
    setCopiado(true);
    navigator.clipboard.writeText(Senha);
  }

  return (
    <Background Show={Show}>
      <Form onSubmit={HandleSubmit}>
        <CloseIcon onClick={() => Close()} />
        <Title>Restaurar Usuário</Title>
        <InputLabel
          type="text"
          value={Nome}
          onChange={(e) => setNome(e.target.value.toUpperCase())}
          label="Nome"
          disabled
        />
        <InputLabel
          type="email"
          value={Email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          disabled
        />
        <SenhaContainer>
          {Copiado ? "Copiado" : Senha}
          <Copied title="Copiado" show={Copiado}>
            <FaClipboardCheck />
          </Copied>
          <Copy
            title="Copiar"
            show={!Copiado}
            onClick={() => {
              Copiar();
            }}
          >
            <FaClipboard />
          </Copy>
        </SenhaContainer>
        <Button type="submit">Restaurar</Button>
      </Form>
    </Background>
  );
}
