import React, { useState, useEffect } from "react";
import { Background, Form, Title, Button, CloseIcon } from "./style";
import InputLabel from "../../../InputLabel";
import SelectLabel from "../../../SelectLabel";
import api from "../../../../services/api";
import { Swal, Toast } from "../../../../services/SwalTemplates";

export default function Usuario({ Show, setShow, Dados, setDados, setReload }) {
  const [Nome, setNome] = useState("");
  const [Email, setEmail] = useState("");
  const [Perfil, setPerfil] = useState("");
  const [Senha, setSenha] = useState("");
  const [SenhaConfirmacao, setSenhaConfirmacao] = useState("");
  const [IdUsuario, setIdUsuario] = useState(0);
  const [PerfisValues] = useState(["GESTOR", "OPERADOR", "DEV"]);
  const [PerfisOptions] = useState(["Gestor", "Operador", "Dev"]);

  useEffect(() => {
    if (Dados) {
      setIdUsuario(Dados?.id);
      setNome(Dados?.nome);
      setPerfil(Dados?.perfil);
      setEmail(Dados?.email);
    }
  }, [Dados]);

  async function HandleSubmit(e) {
    e.preventDefault();

    if (Senha && Senha !== SenhaConfirmacao)
      return Swal.fire({
        title: "Atenção!",
        text: "As senhas devem ser iguais!",
        icon: "warning",
      });

    const data = {
      id: IdUsuario,
      nome: Nome,
      email: Email,
      perfil: Perfil,
      senha: Senha,
    };

    if (IdUsuario) {
      api
        .patch(`/usuario`, { data })
        .then((response) => {
          Toast.fire({
            title: response?.data?.success,
            icon: "success",
          });
          Close();
        })
        .catch((e) => {
          Swal.fire({ title: "Atenção!", text: "Error", icon: "error" });
        });
    } else {
      api
        .post(`/register`, { data })
        .then((response) => {
          Swal.fire({
            title: "Sucesso!",
            text: response?.data?.success,
            icon: "success",
            confirmButtonColor: "var(--main-color)",
          });
          Close();
        })
        .catch((e) => {
          Swal.fire({ title: "Atenção!", text: "Error", icon: "error" });
        });
    }
  }

  async function Close() {
    setDados("");
    setShow(false);
    setNome("");
    setEmail("");
    setPerfil("");
    setSenhaConfirmacao("");
    setSenha("");
    setIdUsuario(0);
    setReload((r) => !r);
  }

  return (
    <Background Show={Show}>
      <Form onSubmit={HandleSubmit}>
        <CloseIcon onClick={() => Close()} />
        <Title>{IdUsuario ? "Editar" : "Cadastrar"} Usuário</Title>
        <InputLabel
          type="text"
          value={Nome}
          onChange={(e) => setNome(e.target.value.toUpperCase())}
          label="Nome"
          required
        />
        <InputLabel
          type="email"
          value={Email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          required
        />
        <SelectLabel
          label="Perfil"
          value={Perfil}
          onChange={(e) => setPerfil(e.target.value)}
          defaultValue="Perfil"
          options={PerfisOptions}
          values={PerfisValues}
          required
        ></SelectLabel>
        <InputLabel
          type="password"
          value={Senha}
          onChange={(e) => setSenha(e.target.value)}
          label="Senha"
          minLength={10}
          required={!IdUsuario}
        />
        <InputLabel
          type="password"
          value={SenhaConfirmacao}
          onChange={(e) => setSenhaConfirmacao(e.target.value)}
          minLength={10}
          label="Confirmar Senha"
          required={!IdUsuario}
        />
        <Button type="submit">Salvar</Button>
      </Form>
    </Background>
  );
}
