/**
 * Formata a data conforme o formato fornecido
 * @param {*} DataInicial Data sem formatação
 * @param {*} Formato Formato desejado
 * @returns Data formatada
 */
export const format = ({ DataInicial, Formato }) => {
  if (!DataInicial || !Formato) {
    return undefined;
  }

  function AddZero(Value) {
    return Value.toString().length > 1 ? Value : `0${Value}`;
  }

  /**
   * Trasnforma as variávies no formato correto
   */
  if (typeof DataInicial !== "string") {
    let NewDate = new Date(DataInicial);
    DataInicial = `${AddZero(NewDate.getDate())}-${AddZero(
      NewDate.getMonth() + 1
    )}-${AddZero(NewDate.getFullYear())}`;
  }
  Formato = Formato?.toString();

  let DataArray = DataInicial.includes("-")
    ? DataInicial.split("-")
    : DataInicial.split("/");

  let DD = DataArray[0].length === 4 ? DataArray[2] : DataArray[0];
  let MM = DataArray[1];
  let YYYY = DataArray[0].length === 4 ? DataArray[0] : DataArray[2];
  let Hours;

  if (DD.includes("T")) {
    [DD, Hours] = DD.split("T")
  }

  let DataFormatada = Formato.replace("DD", DD)
    .replace("MM", MM)
    .replace("YYYY", YYYY)
    .replace("HH:mm:ss", Hours)
    .replace("T", " - ")
    .replace(":00.000Z", "")
    .replace(".000Z", "");

  return DataFormatada;
};

/**
 * Adiciona uma certa quantidade de dias, meses ou anos à uma data
 * @param {*} DataInicial Data inicial
 * @param {*} Valor Quantidade a ser acrescida
 * @param {*} FormatoValor D para dia, M para mês e Y para ano
 * @returns Data com acréscimo
 */
export const add = ({ DataInicial, Valor, FormatoValor }) => {
  if (!DataInicial || !Valor || !FormatoValor) {
    return undefined;
  }

  let DataFormatada = new Date(DataInicial);

  switch (FormatoValor) {
    case "D":
      DataFormatada.setDate(DataFormatada.getDate() + parseInt(Valor, 10));
      break;

    case "M":
      DataFormatada.setMonth(DataFormatada.getMonth() + parseInt(Valor, 10));
      break;

    case "Y":
      DataFormatada.setFullYear(
        DataFormatada.getFullYear() + parseInt(Valor, 10)
      );
      break;

    default:
      DataFormatada = undefined;
      break;
  }

  return DataFormatada;
};

/**
 * Calcula a diferença entre datas
 * @param {*} DataInicial_1 Primeira data
 * @param {*} DataInicial_2 Segunda data
 * @returns Quantidade em dias
 */
export const difference = ({ DataInicial_1, DataInicial_2 }) => {
  if (!DataInicial_1 || !DataInicial_2) {
    return undefined;
  }

  if (
    DataInicial_1?.split("-")[0]?.length === 2 ||
    DataInicial_1?.split("/")[0]?.length === 2
  ) {
    DataInicial_1 = format({
      DataInicial: DataInicial_1,
      Formato: "YYYY-MM-DD",
    });
  }

  if (
    DataInicial_2?.split("-")[0]?.length === 2 ||
    DataInicial_2?.split("/")[0]?.length === 2
  ) {
    DataInicial_2 = format({
      DataInicial: DataInicial_2,
      Formato: "YYYY-MM-DD",
    });
  }

  DataInicial_1 = new Date(DataInicial_1).getTime();
  DataInicial_2 = new Date(DataInicial_2).getTime();

  let Valor;
  let Result;


  Valor = new Date(DataInicial_2 - DataInicial_1);

  
  if (Valor < 0) {
    Valor = 0;
  }
  Result = Math.round(Valor / (1000 * 3600 * 24));

  if (Result !== 0) Result += 1

  return Result;
};
