import styled from "styled-components";
import { HiTrash, HiPencil } from "react-icons/hi";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdScreenShare } from "react-icons/md";

export const Background = styled.div`
  min-width: 100vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`;

export const Container = styled.div`
  height: fit-content;
  width: 90%;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScrollTable = styled.div`
  overflow-x: auto;
  align-self: flex-start;
  width: 100%;
  height: fit-content;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: var(--table-primary-color);
  border-radius: 5px;

  td,
  th {
    padding: 10px;
  }

  tbody {
    tr:hover {
      background-color: var(--table-secondary-color);
    }
  }

  tr {
    border: 1px solid var(--table-border-color);
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const Button = styled.button`
  border: none;
  height: 40px;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
`;

export const RemoveIcon = styled(HiTrash)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const EditIcon = styled(HiPencil)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const SenhaIcon = styled(RiLockPasswordFill)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const AccessIcon = styled(MdScreenShare)`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};

  &:hover {
    transform: ${(props) => !props.disabled && "scale(1.2)"};
    color: ${(props) => !props.disabled && "var(--main-color)"};
  }
`;
