import { MdNotificationsActive, MdNotificationsNone } from "react-icons/md";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  0% {
    transform: rotate(-10deg);
  }

  25% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const Counter = styled.div`
  width: 25px;
  height: fit-content;
  padding: 0 5px;
  background-color: var(--main-color);
  border-radius: 10px;
  color: var(--secondary-font-color);
  position: absolute;
  bottom: 0px;
  right: -20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

export const ActiveNotificationsIcon = styled(MdNotificationsActive)`
  cursor: pointer;
  font-size: 30px;
  color: var(--secondary-font-color);
  transform-origin: top center;
  animation-iteration-count: 5;

  &:hover {
    animation: ${rotate} 0.8s ease;
    color: var(--main-color);
  }
`;

export const NoneNotificationsIcon = styled(MdNotificationsNone)`
  cursor: pointer;
  font-size: 25px;
  color: var(--secondary-font-color);

  &:hover {
    color: var(--main-color);
  }
`;
