import styled from "styled-components";
import { IoSend, IoClose } from "react-icons/io5";
import { HiDotsVertical } from "react-icons/hi";
import { BiCheckDouble } from "react-icons/bi";

export const Background = styled.div`
  min-width: 100vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 50px;
`;

export const Container = styled.div`
  height: fit-content;
  width: 95%;
  height: calc(100vh - 220px);
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const Search = styled.input`
  height: 3rem;
  margin: 0.5rem;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 1rem;
  font-size: 16px;
  background-color: #cecece;
`;

export const ContatosContainer = styled.div`
  width: 30%;
  height: 100%;
  background-color: var(--first-auxiliary-color);
  display: flex;
  flex-direction: column;
  transition: width 0.5s;

  & > span {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  @media (max-width: 900px) {
    width: ${(props) => (!!props.hidden ? `0` : "100%")};
    overflow-x: hidden;
  }
`;

export const Contato = styled.div`
  color: ${(props) =>
    props.selected ? "var(--light-cyan-color)" : "var(--contato-font-color)"};
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid var(--secondary-bg-color);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 0.5rem;
  background-color: ${(props) =>
    props.selected && "var(--table-secondary-color)"};
  gap: 1rem;
`;

export const NaoLidos = styled.div`
  background-color: var(--main-color);
  padding: 0.5rem;
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  color: var(--gunmetal-color);
`;

export const ChatContainer = styled.div`
  width: 70%;
  height: 100%;
  background-color: var(--secondary-bg-color);
  display: flex;
  flex-direction: column;
  transition: width 0.5s;

  @media (max-width: 900px) {
    width: ${(props) => (!!props.hidden ? `100%` : "0px")};
    overflow-x: hidden;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 5rem;
  background-color: var(--first-auxiliary-color);
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0 1rem;
  align-items: center;
  padding: 0 1rem;
`;

export const CloseIcon = styled(IoClose)`
  font-size: 3rem;
  cursor: pointer;
  color: var(--secondary-font-color);
  opacity: 0.8;
  transition: transform 0.2s;
  visibility: ${(props) => props.hidden && "hidden"};

  &:hover {
    transform: scale(1.1);
  }
`;

export const User = styled.div`
  color: var(--secondary-font-color);

  p:nth-child(1) {
    font-size: 1rem;
  }

  p:nth-child(2) {
    font-size: 0.6rem;
  }
`;

export const BackgroundClose = styled.div`
  display: ${(props) => !props.Show && "none"};
  z-index: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: transparent;
  position: fixed;
  top: 60px;
  left: 0;
`;

export const MenuContainer = styled.div`
  position: relative;
`;

export const Menu = styled.div`
  position: absolute;
  top: 4rem;
  right: 0;
  flex-direction: column;
  display: ${(props) => (!!props.show ? "flex" : "none")};
`;

export const MenuOption = styled.div`
  padding: 1rem;
  cursor: pointer;
  background-color: var(--first-auxiliary-color);
  color: var(--secondary-font-color);

  &:first-child {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -10px;
      right: 6px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent var(--first-auxiliary-color)
        transparent;
    }
  }
`;

export const ThreeDots = styled(HiDotsVertical)`
  font-size: 2rem;
  cursor: pointer;
  color: var(--secondary-font-color);
  opacity: 0.8;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const Messages = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  gap: 0.3rem;
  flex-direction: column-reverse;
  justify-content: flex-start;
  padding: 2rem;

  span {
    font-size: 0.8rem;
  }

  h3 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--primary-font-color);
  }

  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3d5a807f;
    background-color: var(--chat-scroll-color);
  }
`;

export const Data = styled.div`
  align-self: center;
  background-color: var(--first-auxiliary-color);
  padding: 0.3rem;
  border-radius: 0.2rem;
  font-size: 13px;
  color: var(--secondary-font-color);
  letter-spacing: 0.02rem;
`;

export const MessageRight = styled.div`
  align-self: flex-end;
  background-color: var(--main-color);
  padding: 0.8rem;
  border-radius: 1rem 1rem 0 1rem;
  max-width: 100%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;

  div {
    align-self: flex-end;
  }
`;

export const MessageLeft = styled.div`
  background-color: var(--main-color);
  align-self: flex-start;
  padding: 0.8rem;
  border-radius: 0 1rem 1rem 1rem;
  max-width: 100%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;

  span {
    align-self: flex-end;
  }
`;

export const Time = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
`;

export const Check = styled(BiCheckDouble)`
  font-size: 20px;
  color: ${(props) => (!!props.lido ? "#1380ff" : "#8d99ae")};
`;

export const InputContainer = styled.form`
  height: 5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--first-auxiliary-color);
  gap: 1.5rem;
`;

export const Input = styled.input`
  height: 100%;
  padding: 1rem 0.8rem;
  width: 100%;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 16px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #cecece;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  width: fit-content;
  height: fit-content;
  border: none;
  outline: none;
`;

export const SendIcon = styled(IoSend)`
  color: var(--secondary-font-color);
  font-size: 1.8rem;
  cursor: pointer;
  transition: transform 0.5s;

  &:hover {
    transform: scale(1.1);
  }
`;
