import React, { useState } from "react";
import {
  Background,
  Container,
  Input,
  CloseIcon,
  Image,
  RemoveIcon,
  ImageContainer,
  Button,
  CameraIcon,
  CameraInput,
  CameraContainer,
} from "./style";
import InputLabel from "../InputLabel";
import PdfImage from "../../assets/images/pdf.png";
import XlsImage from "../../assets/images/xls.png";
import { Swal, Toast } from "../../services/SwalTemplates";
import api from "../../services/api";

export default function FileInput({
  Show,
  setShow,
  NumProcesso,
  IdProcesso,
  Seq,
  setRealodData,
  setAnexo,
  Anexo,
  Origin,
}) {
  const [NomeArquivo, setNomeArquivo] = useState("");
  const [NomeAnexo, setNomeAnexo] = useState("");
  const [Tipo, setTipo] = useState("");
  const [File, setFile] = useState("");
  const [FileImg, setFileImg] = useState("");
  const [HighlightInput, setHighlightInput] = useState(false);

  async function HandleSubmit(e) {
    e.preventDefault();

    const data = {
      seq_ocorrencia: null,
      titulo_documento: NomeAnexo,
      processo: NumProcesso,
      id_processo: IdProcesso,
    };
    if (Origin === "Anexo") data.seq_ocorrencia = Seq;

    const formData = new FormData();

    // eslint-disable-next-line array-callback-return
    Object.keys(data).map((key) => {
      formData.append(key, data[key]);
    });
    formData.append("file", File);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    console.log(File);

    if (Origin === "Anexo") {
      api
        .post(`/anexos`, formData, config)
        .then((response) => {
          Toast.fire({
            title: response?.data?.success,
            icon: "success",
          });
          setRealodData((rd) => !rd);
        })
        .catch((e) => {
          Swal.fire({ title: "Atenção!", text: e, icon: "error" });
        });
    } else if (Origin === "Ocorrencia") {
      setAnexo(formData);
    }

    Close();
  }

  async function Close() {
    setShow(false);
    setNomeArquivo("");
    setTipo("");
    setFile("");
    setNomeAnexo("");
  }

  return (
    <Background Show={Show}>
      <Container>
        <CloseIcon onClick={() => Close()} />
        {File ? (
          <>
            <ImageContainer>
              <RemoveIcon onClick={() => setFile("")} />
              <Image
                src={
                  Tipo.includes("image")
                    ? FileImg
                    : Tipo.includes("pdf")
                    ? PdfImage
                    : Tipo.includes("sheet")
                    ? XlsImage
                    : ""
                }
                alt="Erro"
              />
            </ImageContainer>
            <InputLabel
              type="text"
              label="Nome Arquivo"
              disabled
              value={NomeArquivo}
              required
            />
            <InputLabel
              required
              type="text"
              label="Assunto"
              value={NomeAnexo}
              onChange={(e) => setNomeAnexo(e.target.value)}
            />
            <Button type="submit" onClick={HandleSubmit} disabled={!NomeAnexo}>
              ANEXAR
            </Button>
          </>
        ) : (
          <>
            <CameraContainer>
              <CameraInput
                type="file"
                accept="image/*"
                name="image"
                onChange={(e) => {
                  if (
                    e?.target?.files[0]?.type.includes("image") ||
                    e?.target?.files[0]?.type.includes("pdf") ||
                    e?.target?.files[0]?.type.includes("sheet")
                  ) {
                    setFileImg(URL.createObjectURL(e?.target?.files[0]));
                    setFile(e?.target?.files[0]);
                    setTipo(e?.target?.files[0]?.type);
                    setNomeArquivo(e?.target?.files[0]?.name);
                  } else {
                    Swal.fire({
                      title: "Atenção!",
                      text: "Formato não permitido!",
                      icon: "warning",
                    });
                  }
                }}
                capture
              />
              <CameraIcon />
            </CameraContainer>
            <Input
              type="file"
              accept="image/*,.pdf,.xlsx"
              onDragEnter={() => {
                setHighlightInput(true);
              }}
              HighlightInput={HighlightInput}
              onDragLeave={() => {
                setHighlightInput(false);
              }}
              onDrop={() => {
                setHighlightInput(false);
              }}
              onChange={(e) => {
                if (
                  e?.target?.files[0]?.type.includes("image") ||
                  e?.target?.files[0]?.type.includes("pdf") ||
                  e?.target?.files[0]?.type.includes("sheet")
                ) {
                  setFileImg(URL.createObjectURL(e?.target?.files[0]));
                  setFile(e?.target?.files[0]);
                  setTipo(e?.target?.files[0]?.type);
                  setNomeArquivo(e?.target?.files[0]?.name);
                } else {
                  Swal.fire({
                    title: "Atenção!",
                    text: "Formato não permitido!",
                    icon: "warning",
                  });
                }
              }}
            />
          </>
        )}
      </Container>
    </Background>
  );
}
