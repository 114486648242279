import React, { useState, useEffect } from "react";
import { Background, Form, Title, Button, CloseIcon } from "./style";
import InputLabel from "../../InputLabel";
import SelectLabel from "../../SelectLabel";
import Texts from "../../../assets/json/texts.json";
import { Swal } from "../../../services/SwalTemplates";
import { userId } from "../../../services/TokenData";
import TextareaLabel from "../../TextareaLabel";
import FileInput from "../../FileInput";
import api from "../../../services/api";
import { add, format, difference } from "../../../services/DateFormatter";

export default function EditOcorrencia({
  Show,
  setShow,
  DadosOcorrencia,
  Reload,
  setReload,
  DadosProcesso,
}) {
  const [Descricao, setDescricao] = useState("");
  const [NovaSituacao, setNovaSituacao] = useState("");
  const [Destinatarios, setDestinatarios] = useState(null);
  const [IdDestinatarios, setIdDestinatarios] = useState(null);
  const [IdDestinatario, setIdDestinatario] = useState(0);
  const [QuantDias, setQuantDias] = useState(0);
  const [ShowFileInput, setShowFileInput] = useState(false);
  const [Anexo, setAnexo] = useState(null);
  const [RealodData, setRealodData] = useState(false);
  const [DataConclusao, setDataConclusao] = useState(
    format({ DataConclusao: new Date(), format: "YYYY-MM-DD" })
  );
  const [Situacao, setSituacao] = useState("");
  const [Concluido, setConcluido] = useState("Não");
  const [DataPrevista, setDataPrevista] = useState(
    format({ DataConclusao: new Date(), format: "YYYY-MM-DD" })
  );
  const [DataAlteraPrazoOcorrencia, setDataAlteraPrazoOcorrencia] = useState(0);

  useEffect(() => {
    setDescricao(DadosOcorrencia?.descricao);
    setDataPrevista(DadosOcorrencia?.data_prevista);
  }, [Show]);

  async function HandleSubmit(e) {
    e.preventDefault();

    const data = {
      num_processo: DadosOcorrencia?.id_processo,
      descricao: Descricao,
      id_usuario_acao: userId(),
      data_conclusao:
        Concluido === "Sim" &&
        DataConclusao !== "" &&
        DataConclusao !== null &&
        DataConclusao !== undefined &&
        DataConclusao !== "00/00/0000"
          ? DataConclusao
          : Concluido === "Sim"
          ? new Date()
          : null,
      situacao:
        Concluido === "Sim"
          ? "Concluído"
          : Situacao === ""
          ? "Executando"
          : Situacao,
      data_prevista:
        DataAlteraPrazoOcorrencia > 0
          ? new Date(
              add({
                DataInicial: format({
                  DataInicial: DataPrevista,
                  Formato: "YYYY-MM-DD",
                }),
                Valor: Number(DataAlteraPrazoOcorrencia),
                FormatoValor: "D",
              })
            )
          : new Date(
              format({
                DataInicial: DataPrevista,
                Formato: "MM-DD-YYYY",
              })
            ),
    };

    api
      .patch(
        `/ocorrencias/${DadosOcorrencia?.id_processo}/${DadosOcorrencia?.seq_ocorrencia}`,
        { data }
      )
      .then((response) => {
        if (!!Anexo) {
          const config = {
            headers: { "content-type": "multipart/form-data" },
          };
          Anexo.set("seq_ocorrencia", DadosOcorrencia?.seq_ocorrencia);

          api
            .post(`/anexos`, Anexo, config)
            .then((resAnexo) => {
              Swal.fire({
                title: "Sucesso!",
                text: response?.data?.success,
                icon: "success",
                confirmButtonColor: "var(--main-color)",
              });
            })
            .catch((e) => {
              Swal.fire({ title: "Atenção!", text: e, icon: "error" });
            });
        } else {
          Swal.fire({
            title: "Sucesso!",
            text: response?.data?.success,
            icon: "success",
            confirmButtonColor: "var(--main-color)",
          });
        }
        Close();
        setReload(!Reload);
      })
      .catch((e) => {
        Swal.fire({ title: "Atenção!", text: "Error", icon: "error" });
      });
  }

  async function Close() {
    setDescricao("");
    setNovaSituacao("");
    setIdDestinatario(0);
    setQuantDias(0);
    setAnexo(null);
    setShow(false);
  }

  return (
    <Background Show={Show}>
      <Form onSubmit={HandleSubmit}>
        <CloseIcon onClick={() => Close()} />
        <Title>Editar Ocorrência</Title>
        <TextareaLabel
          value={Descricao}
          onChange={(e) => setDescricao(e.target.value)}
          label="Descrição"
          id={"ocorrencia-descricao"}
          required
        />
        <SelectLabel
          type="text"
          value={Concluido}
          onChange={(e) => setConcluido(e.target.value)}
          label="Ocorrencia Concluída?"
          defaultValue={Concluido}
          options={Texts?.STATIC_OPC_CONCLUIDO_OCORRENCIAS}
          values={Texts?.STATIC_OPC_CONCLUIDO_OCORRENCIAS}
        />
        <InputLabel
          type="date"
          value={DataConclusao}
          onChange={(e) => setDataConclusao(e.target.value)}
          label="Data Conclusão"
          disabled={Concluido !== "Não" ? null : "disabled"}
        />
        <SelectLabel
          type="text"
          value={Situacao}
          onChange={(e) => setSituacao(e.target.value)}
          label="Alterar Situação da Ocorrência ?"
          defaultValue="Situação da ocorrencia"
          options={Texts?.STATIC_SITUACOES_OCORRENCIAS}
          values={Texts?.STATIC_SITUACOES_OCORRENCIAS}
        />
        <Button type="button" onClick={() => setShowFileInput(true)}>
          Anexar arquivo
        </Button>
        <FileInput
          setShow={setShowFileInput}
          Show={ShowFileInput}
          DadosProcesso={DadosProcesso}
          IdProcesso={DadosOcorrencia?.id_processo}
          setAnexo={setAnexo}
          Origin={"Ocorrencia"}
          setRealodData={setRealodData}
        />
        <Button type="submit">Salvar</Button>
      </Form>
    </Background>
  );
}
