import React, { useEffect, useRef, useState } from "react";
import {
  Background,
  Button,
  CloseIcon,
  Container,
  Buttons,
  Image,
  CanvasContainer,
} from "./style";
import api from "../../../services/api";
import { Swal } from "../../../services/SwalTemplates";
import Texts from "../../../assets/json/texts.json";
import { userId } from "../../../services/TokenData";

const SignPad = ({ Show, SetShow }) => {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const [IsDrawing, setIsDrawing] = useState(false);
  const Token = localStorage.getItem("token");
  const [Img, setImg] = useState("");
  const [HasImage, setHasImage] = useState(true);

  useEffect(() => {
    setImg(
      `${
        window.location.href.includes("localhost")
          ? Texts.STATIC_URL_DEV
          : Texts.STATIC_URL_PROD
      }/assinaturas?id=${userId()}&auth=Bearer ${Token}`
    );
  }, [Token]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.lineCap = "round";
    context.strokeStyle = "black";
    context.fillStyle = "white";
    context.fillRect(0, 0, 500, 200);
    context.lineWidth = 5;
    context.lineJoin = "round";
    contextRef.current = context;
  }, [HasImage]);

  function startDrawing({ nativeEvent }) {
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  }

  function finishDrawing() {
    contextRef.current.closePath();
    setIsDrawing(false);
  }

  function draw({ nativeEvent }) {
    if (!IsDrawing) return;
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  }

  function enviar() {
    canvasRef.current.toBlob((blob) => {
      const file = new File([blob], "fileName.jpeg", { type: "image/jpeg" });
      // const img = canvasRef.current.toDataURL("image/webp");
      // Swal.fire({
      //   text: "Assinado!",
      //   imageUrl: img,
      //   imageAlt: "A tall image",
      // });

      const data = new FormData();
      data.append("file", file);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      api
        .post(`/assinaturas`, data, config)
        .then((response) => {
          Swal.fire({
            title: "Assinado!",
            text: response.data.success,
            icon: "success",
          }).then(() => window.location.reload());
        })
        .catch((e) => {
          Swal.fire({ title: "Atenção!", text: e, icon: "error" });
        });
    }, "image/jpeg");
  }

  return (
    <Background Show={Show}>
      <Container>
        <CloseIcon
          onClick={() => {
            SetShow(false);
            contextRef.current.clearRect(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            );
          }}
        />
        <Image
          src={Img}
          image={HasImage.toString()}
          onError={() => {
            setHasImage(false);
          }}
          onLoad={() => {
            setHasImage(true);
          }}
        />
        <CanvasContainer image={HasImage.toString()}>
          <canvas
            onPointerDown={startDrawing}
            onPointerUp={finishDrawing}
            onPointerMove={draw}
            onPointerLeave={finishDrawing}
            width={500}
            height={200}
            ref={canvasRef}
          />
        </CanvasContainer>
        <Buttons>
          <Button onClick={() => enviar()}>assinar</Button>
          <Button
            onClick={() => {
              setHasImage(false);
              contextRef.current.clearRect(
                0,
                0,
                canvasRef.current.width,
                canvasRef.current.height
              );
            }}
          >
            Limpar
          </Button>
        </Buttons>
      </Container>
    </Background>
  );
};

export default SignPad;
