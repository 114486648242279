import styled from "styled-components";
import { FaRegUserCircle } from "react-icons/fa";

export const Background = styled.div`
  min-width: 100vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  padding-top: 80px;
  padding-bottom: 50px;
  color: var(--primary-font-color);
  
  .myProfile{
    height: 520px;
    width: 50%;
    background-color: var(--secondary-bg-color);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    border: 2px solid black;
    margin: 0 1.5% 0 3%;
    p{
      width:50%;
      font-size:19px;
    }
      strong{
        
      }
    
  }
  
  .changeProfile{
    min-height: 520px;
    height:fit-content;
    width: 50%;
    background-color: var(--secondary-bg-color);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    gap: 20px;
    border: 2px solid black;
    margin: 0 3% 0 1.5%;
  }
`;

export const Container = styled.form`
  height: fit-content;
  width: 95%;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 30px;
  
`;

export const Title = styled.h1`
  color: var(--primary-font-color);
  text-align: center;
`;

export const Button = styled.button`
  border: none;
  height: 40px;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
`;
export const IconPerfil = styled(FaRegUserCircle)`
  width:25%;
  height:25%;
  color: var(--secondary-font-color);
  margin: 2% 0 3% 0;
`;