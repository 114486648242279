import styled, { keyframes } from "styled-components";
import { FiLogOut } from "react-icons/fi";
import { MdNotificationsNone, MdNotificationsActive } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaCalendarDays } from "react-icons/fa6";
import { FaFileSignature } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { FaDoorOpen } from "react-icons/fa";
import { BiLineChart } from "react-icons/bi";

export const Background = styled.header`
  position: relative;
  width: 100%;
  height: 60px;
  background-color: var(--first-auxiliary-color);
  display: ${(props) => !props.Show && "none"};

  @media (max-width: 900px) {
    display: ${(props) => props.Type === `desktop` && "none"};
  }

  @media (min-width: 901px) {
    display: ${(props) => props.Type === `mobile` && "none"};
  }
`;

export const Container = styled.nav`
  padding-right: 30px;
  width: 97%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 2%;

  .dash{
    display:none;
   
  }
  .primeiro:hover .dash{
    display:block;
  }  
  .primeiro:focus .dash{
    display:block;
  }  
  
  .solic{
    display:none;
   
  }
  .segundo:hover .solic{
    display:block;
  }  
  .segundo:focus .solic{
    display:block;
  }  

  .users{
    display:none;
   
  }
  .terceiro:hover .users{
    display:block;
  }  
  .terceiro:focus .users{
    display:block;
  }  
   
  .acess{
    display:none;
   
  }
  .quarto:hover .acess{
    display:block;
  }  
  .quarto:focus .acess{
    display:block;
  }  

  .userContent {
    display: flex;
    align-items: center;
    gap: 60px;
    height: 100%;
    margin-left: auto;
    
  }
   

  @media (max-width: 900px) {
    justify-content: space-around;
    padding-left: 70px;
  }
`;

export const Navlink = styled(NavLink)`
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--secondary-font-color);
  padding: 0 10px;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
  font-size: 15px;

  &:after {
    content: "";
    top: 100%;
    left: 0;
    width: 100%;
    height: 5px;
    position: absolute;
    transition: background-color 0.3s;
  }

  &:hover,
  &.active {
    &:after {
      background-color: var(--main-color);
    }
  }
`;

export const Logo = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: center;
  margin-left: 20px;
`;

export const Title = styled.h1`
  position: absolute;
  background-color: var(--first-auxiliary-color);
  top: 100%;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-font-color);
  padding: 5px 0;

  &:before {
    content: "";
    right: 100%;
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 40px 0;
    border-color: transparent var(--first-auxiliary-color) transparent
      transparent;
  }

  &:after {
    content: "";
    left: 100%;
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 30px 0 0;
    border-color: var(--first-auxiliary-color) transparent transparent
      transparent;
  }
`;

export const Img = styled.img`
  object-fit: cover;
  height: 70%;
`;

export const LogoutIcon = styled(FiLogOut)`
  cursor: pointer;
  font-size: 25px;
  color: var(--secondary-font-color);
  transition: transform 0.2s;
  margin-left: auto;

  &:hover {
    color: var(--main-color);
    transform: scale(1.1);
  }
`;

const rotate = keyframes`
0% {
  transform: rotate(-10deg);
}

25% {
  transform: rotate(10deg);
}

50% {
  transform: rotate(-10deg);
}

100% {
  transform: rotate(0deg);
}
`;

export const ActiveNotificationsIcon = styled(MdNotificationsActive)`
  cursor: pointer;
  font-size: 25px;
  color: var(--secondary-font-color);
  transform-origin: top center;
  animation-iteration-count: 5;

  &:hover {
    animation: ${rotate} 0.8s ease;
    color: var(--main-color);
  }
`;

export const NoneNotificationsIcon = styled(MdNotificationsNone)`
  cursor: pointer;
  font-size: 25px;
  color: var(--secondary-font-color);

  &:hover {
    color: var(--main-color);
  }
`;

export const MenuIcon = styled.div`
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.OpenMenu ? `0` : `2px 0`)};
  transition: transform 0.2s;
  transition: padding 0.2s;

  &:hover {
    transform: scale(1.1);

    div {
      background-color: var(--main-color);
    }
  }

  div {
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: var(--secondary-font-color);
    transition: transform 0.2s;

    &:nth-child(1) {
      transform-origin: 24px 3.5px;
      transform: ${(props) =>
    props.OpenMenu ? `rotate(-45deg) scaleX(1.3)` : `rotate(0) scaleX(1)`};
    }
    &:nth-child(2) {
      transform-origin: center;
      transform: ${(props) =>
    props.OpenMenu ? `rotate(45deg) scaleX(1.35)` : `rotate(0) scaleX(1)`};
    }
    &:nth-child(3) {
      transform-origin: 1px 1px;
      transform: ${(props) =>
    props.OpenMenu ? `rotate(-45deg) scaleX(1.3)` : `rotate(0) scaleX(1)`};
    }
  }
`;

export const MobileMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileContainer = styled.div`
  width: 100%;
  background-color: var(--first-auxiliary-color);
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: height 0.3s, border-color 0.3s;
  height: ${(props) => (props.OpenMenu ? "calc(100vh - 120px)" : 0)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10%;
  border-bottom: ${(props) =>
    props.OpenMenu ? "5px solid var(--main-color)" : "none"};
  margin-bottom: ${(props) => (props.OpenMenu ? "-5px" : "0")};

  span {
    text-decoration: none;
    height: fit-content;
    color: var(--secondary-font-color);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05em;
    font-size: 18px;
    display: flex;
    align-items: center;
    transition: font-size 0.5s;
    padding: 10px 5px;
    cursor: pointer;

    &:hover {
      font-size: 25px;
      border-bottom: 5px solid var(--main-color);
    }
  }
`;

export const MobileTitle = styled.h1`
  background-color: ${(props) =>
    props.OpenMenu ? "var(--main-color)" : "var(--first-auxiliary-color)"};
  cursor: pointer;
  top: 100%;
  height: 40px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-font-color);
  padding: 5px 0;
  position: relative;
  transition: background-color 0.3s;
  z-index: 1;

  &:before {
    content: "";
    right: 100%;
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 40px 0;
    transition: border-color 0.3s;
    border-color: ${(props) =>
    props.OpenMenu
      ? "transparent var(--main-color) transparent transparent"
      : "transparent var(--first-auxiliary-color) transparent transparent"};
  }

  &:after {
    content: "";
    left: 100%;
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 30px 0 0;
    transition: border-color 0.3s;
    border-color: ${(props) =>
    props.OpenMenu
      ? "var(--main-color) transparent transparent transparent"
      : "var(--first-auxiliary-color) transparent transparent transparent"};
  }
`;

export const MobileNavlink = styled(NavLink)`
  text-decoration: none;
  height: fit-content;
  color: var(--secondary-font-color);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
  font-size: 18px;
  display: flex;
  align-items: center;
  transition: font-size 0.5s;
  padding: 10px 5px;

  &:hover,
  &.active {
    font-size: 25px;
    border-bottom: 5px solid var(--main-color);
  }
`;
export const Dashboard = styled(BiLineChart)`
  cursor: pointer;
  margin-right: 10px;
  font-size:30px;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
    .dash{
      display: contents;
    }
  }
`;

export const Solicitacao = styled(FaFileSignature)`
  cursor: pointer;
  margin-right: 10px;
  font-size:30px;
  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;
export const Usuarios = styled(FaUsers)`
  cursor: pointer;
  margin-right: 10px;
  font-size:30px;
  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;
export const Acessos = styled(FaDoorOpen)`
  cursor: pointer;
  margin-right: 10px;
  font-size:30px;
  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

