import styled from "styled-components";
import { HiTrash, HiPencil } from "react-icons/hi";
import { FiPaperclip } from "react-icons/fi";
import { FaEye } from "react-icons/fa";
import { MdPictureAsPdf } from "react-icons/md";

export const Title = styled.div`
  margin-bottom: 35px;
`;

export const Background = styled.div`
  min-width: 99vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 50px;

  @media (max-width: 1440px) {
    padding-top: 50px;
    padding-bottom: 0px;
  }
`;

export const Container = styled.div`
  height: fit-content;
  width: 95%;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: center;

  @media (max-width: 1440px) {
    padding: 15px;
  }
`;

export const ScrollTable = styled.div`
  overflow-x: auto;
  align-self: flex-start;
  width: 100%;
  height: fit-content;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: var(--table-primary-color);
  border-radius: 5px;

  .A {
    background-color: var(--alerta-amarelo-color);
  }

  .V {
    background-color: var(--alerta-vermelho-color);
  }

  td,
  th {
    padding: 10px;
  }

  tbody {
    tr:hover {
      background-color: var(--table-secondary-color);
    }
  }

  tr {
    border: 1px solid var(--table-border-color);
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
`;

export const Button = styled.button`
  border: none;

  height: 40px;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
`;

export const RemoveIcon = styled(HiTrash)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const EditIcon = styled(HiPencil)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const AnexoIcon = styled(FiPaperclip)`
  color: var(--main-color);
  margin-left: 5px;
`;

export const ShowIcon = styled(FaEye)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const PdfIcon = styled(MdPictureAsPdf)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;
