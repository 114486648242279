import styled, { keyframes } from "styled-components";
import { IoClose } from "react-icons/io5";
import { HiTrash } from "react-icons/hi";
import { FiCamera } from "react-icons/fi";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.Show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &:before {
    z-index: 1;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--blur-bg-color);
    position: fixed;
    top: 0;
    left: 0;
  }
`;

export const Container = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  padding: 50px 50px 20px;
  border-radius: 5px;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-bg-color);
  z-index: 2;
`;

const RotatingBorder = keyframes`
  to {
        background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
`;

export const Input = styled.input`
  position: relative;
  width: 150px;
  height: 150px;
  color: transparent;
  background: linear-gradient(
      90deg,
      var(--primary-font-color) 50%,
      transparent 50%
    ),
    linear-gradient(90deg, var(--primary-font-color) 50%, transparent 50%),
    linear-gradient(0deg, var(--primary-font-color) 50%, transparent 50%),
    linear-gradient(0deg, var(--primary-font-color) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 16px 4px, 16px 4px, 4px 16px, 4px 16px;
  background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
  border-radius: 5px;
  padding: 10px;
  animation: ${RotatingBorder} 5s linear infinite;
  animation-play-state: ${(props) =>
    props.HighlightInput ? "running" : "paused"};

  ::-webkit-file-upload-button {
    display: none;
  }

  &:before {
    content: "Clique ou arraste um arquivo aqui!";
    position: absolute;
    white-space: normal;
    text-align: center;
    color: black;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${(props) =>
      props.HighlightInput ? "var(--main-color)" : "var(--primary-font-color)"};
  }
`;

export const CloseIcon = styled(IoClose)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 40px;
  transition: transform 0.2s;
  color: var(--second-auxiliary-color);

  &:hover {
    transform: scale(1.2);
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  padding: 10px;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  position: relative;

  &[alt]:after {
    content: "Não suportado!";
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: var(--secondary-bg-color);
    color: var(--primary-font-color);
  }
`;

export const RemoveIcon = styled(HiTrash)`
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const Button = styled.button`
  border: none;
  height: 40px;
  border-radius: 5px;
  padding: 0 30px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    border: 2px dashed var(--primary-bg-color);
    background-color: var(--secondary-bg-color);
    color: var(--primary-bg-color);
  }
`;

export const CameraContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }
`;

export const CameraIcon = styled(FiCamera)`
  cursor: pointer;
  border-radius: 5px;
  color: var(--primary-font-color);
  background-color: var(--main-color);
  padding: 3px;
  height: 35px;
  width: 35px;
  z-index: -1;

  &:active {
    background-color: var(--gunmetal-color);
  }
`;

export const CameraInput = styled.input`
  border-radius: 5px;
  cursor: pointer;
  top: 0;
  width: 35px;
  height: 36px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
`;
