import styled from "styled-components";

export const Background = styled.div`
  min-width: 100vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`;

export const Container = styled.div`
  height: fit-content;
  width: 90%;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScrollTable = styled.div`
  overflow-x: auto;
  align-self: flex-start;
  width: 100%;
  height: fit-content;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: var(--table-primary-color);
  border-radius: 5px;

  td,
  th {
    padding: 10px;
  }

  tbody {
    tr:hover {
      background-color: var(--table-secondary-color);
    }
  }

  tr {
    border: 1px solid var(--table-border-color);
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
`;
