import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { sse } from "../../../services/sse";
import { userId } from "../../../services/TokenData";
import {
  ActiveNotificationsIcon,
  NoneNotificationsIcon,
  Counter,
} from "../../Notifications/style";
import {IconPerfil, Container, Navlink} from "./style"
import { TokenPerfil } from "../../../services/TokenData";
import { useLocation } from "react-router-dom";
import ModalAlertas from "../Alertas";

const MeuPerfil = ({ setShow, Show, DadosUsuario, setDadosUsuario }) => {
  const [Carregado, setCarregado] = useState(false)
  const [Update, setUpdate] = useState(false);
  const UserId = userId();
  const Location = useLocation();

  useEffect(() => {
    if (DadosUsuario.nome === undefined && userId() !== false) {
      api.get(`/usuario?id=${userId()}`).then((response) => {
        setDadosUsuario(response.data.response);
        setCarregado(true);
      });
    }
  });

  useEffect(() => {
    if (Carregado && DadosUsuario && DadosUsuario?.nome) {
      encurtaString(DadosUsuario?.nome)
    }
  }, [Carregado, DadosUsuario]);

  const encurtaString = (nome) => {
    if (nome) {
      const primeiroNome = nome.split(" ");
      return primeiroNome[0];
    } else {
      return "Carregando...";
    }
  }

  useEffect(() => {
    setShow(false);
  }, [Location.pathname, setShow]);

  return (
    <Container>
      <NavLink to={"/perfil"} className="link">
      <IconPerfil/>
      <p>{!!Carregado ? encurtaString(DadosUsuario?.nome) : "Carregando..."}</p>
      </NavLink>
    </Container>
  );
};

export default MeuPerfil;
