import React from "react";
import { useState, useEffect, useCallback } from "react";
import {
  Background,
  Container,
  ContatosContainer,
  ChatContainer,
  Search,
  Header,
  Messages,
  Input,
  InputContainer,
  SendIcon,
  Button,
  MessageRight,
  MessageLeft,
  Contato,
  CloseIcon,
  ThreeDots,
  User,
  NaoLidos,
  Data,
  Check,
  Time,
  MenuContainer,
  Menu,
  MenuOption,
  BackgroundClose,
} from "./style";
import api from "../../services/api";
import { Swal } from "../../services/SwalTemplates";
import { userId } from "../../services/TokenData";
import io from "socket.io-client";
import Texts from "../../assets/json/texts.json";
const Socket = io.connect(
  window.location.href.includes("localhost")
    ? `${Texts.STATIC_URL_DEV}`
    : `${Texts.STATIC_URL_PROD}`
);

export default function Chat() {
  const [Mensagem, setMensagem] = useState("");
  const [Mensagens, setMensagens] = useState([]);
  const [Contatos, setContatos] = useState([]);
  const [IdContatoSelecionado, setIdContatoSelecionado] = useState(0);
  const [SearchContato, setSearchContato] = useState("");
  const [Room, setRoom] = useState("");
  const [PreviousRoom, setPreviousRoom] = useState("");
  const [ShowMenu, setShowMenu] = useState(false);
  const UserId = userId();
  const GetContatos = useCallback(async () => {
    await api.get(`/listcontatos?search=${SearchContato}`).then((response) => {
      setContatos(response?.data?.contatos);
    });
  }, [SearchContato]);
  const GetMensagens = useCallback(async () => {
    await api
      .get(`/mensagens?id_destinatario=${IdContatoSelecionado}`)
      .then((response) => {
        setMensagens((msg) =>
          [
            ...response.data.mensagens,
            ...msg.filter(
              (d) =>
                !response.data.mensagens?.map((m) => m?.id)?.includes(d?.id)
            ),
          ]?.sort((a, b) => b?.id - a?.id)
        );
      });
  }, [IdContatoSelecionado]);

  useEffect(() => {
    async function async() {
      await GetMensagens();
      await GetContatos();
    }
    async();
  }, [GetContatos, GetMensagens, IdContatoSelecionado]);

  useEffect(() => {
    if (Room?.includes("-")) {
      if (Room !== PreviousRoom && !!PreviousRoom) {
        Socket.emit("leave_room", PreviousRoom, () => {
          setPreviousRoom(Room);
        });
      } else {
        setPreviousRoom(Room);
      }
    }
  }, [PreviousRoom, Room]);

  useEffect(() => {
    if (!!IdContatoSelecionado) {
      const user_1 = parseInt(UserId);
      const user_2 = parseInt(IdContatoSelecionado);
      const room = `${user_1 < user_2 ? user_1 : user_2}-${
        user_1 > user_2 ? user_1 : user_2
      }`;
      Socket.emit("join_room", room, () => {
        setRoom(room);
      });
    } else {
      Socket.emit("join_room", UserId.toString(), () => {
        setRoom(UserId.toString());
      });
    }
  }, [IdContatoSelecionado, Room, UserId]);

  useEffect(() => {
    setMensagens([]);
  }, [IdContatoSelecionado]);

  useEffect(() => {
    if (Socket) {
      Socket.on("reload_shared", (msg) => {
        GetMensagens();
        GetContatos();
      });
      Socket.on("reload_single", (msg) => {
        GetContatos();
      });
    }
  }, [GetContatos, GetMensagens]);

  function HandleSubmit(e) {
    e.preventDefault();

    Socket.emit("message", {
      shared: Room,
      single: IdContatoSelecionado.toString(),
    });

    const data = {
      mensagem: Mensagem,
      id_destinatario: IdContatoSelecionado,
    };

    api
      .post(`/mensagem`, { data })
      .then((response) => {
        setMensagem("");
        GetMensagens();
      })
      .catch((error) => {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível enviar!",
          icon: "error",
        });
      });
  }

  return (
    <Background>
      <Container>
        <ContatosContainer hidden={IdContatoSelecionado}>
          <Search
            type="search"
            placeholder="Pesquisar contato..."
            value={SearchContato}
            onChange={(e) => {
              setSearchContato(e.target.value);
            }}
          />
          <span>
            {Contatos?.map((c, i) => (
              <Contato
                key={i}
                title={c?.email}
                onClick={() => {
                  setIdContatoSelecionado(c?.id);
                }}
                selected={IdContatoSelecionado === c?.id}
              >
                <span>{c?.nome}</span>
                {!!c?.nao_lidos && <NaoLidos>{c?.nao_lidos}</NaoLidos>}
              </Contato>
            ))}
          </span>
        </ContatosContainer>
        <ChatContainer hidden={IdContatoSelecionado}>
          <Header>
            <CloseIcon
              hidden={!IdContatoSelecionado}
              onClick={() => {
                setIdContatoSelecionado(0);
                setMensagem("");
                Socket.disconnect();
                setRoom(UserId.toString());
              }}
            />
            <User>
              <p>
                {
                  Contatos?.filter((c) => c.id === IdContatoSelecionado)[0]
                    ?.nome
                }
              </p>
              <p>
                {
                  Contatos?.filter((c) => c.id === IdContatoSelecionado)[0]
                    ?.perfil
                }
              </p>
            </User>
            <MenuContainer>
              <ThreeDots onClick={() => setShowMenu(true)} />
              <BackgroundClose
                onClick={() => setShowMenu(false)}
                Show={ShowMenu}
              />
              <Menu show={ShowMenu}>
                <MenuOption
                  onClick={() =>
                    Swal.fire({
                      title: "Sobre o Chat",
                      html: "<p align='justify'>Este chat foi criado para ser utilizado para comunicações simples e rápidas, não sendo indicado para transferência de arquivos e informações confidenciais!</p>",
                      icon: "info",
                    })
                  }
                >
                  Sobre
                </MenuOption>
              </Menu>
            </MenuContainer>
          </Header>
          <Messages>
            {IdContatoSelecionado ? (
              Mensagens?.map((m, i, a) => (
                <React.Fragment key={i}>
                  {m?.id_remetente === UserId ? (
                    <MessageRight>
                      <p>{m?.mensagem}</p>
                      <Time>
                        <span>{m?.data_horas.split(" ")[1]}</span>
                        <Check lido={m?.lido} />
                      </Time>
                    </MessageRight>
                  ) : (
                    <MessageLeft>
                      <p>{m?.mensagem}</p>
                      <span>{m?.data_horas.split(" ")[1]}</span>
                    </MessageLeft>
                  )}
                  {a
                    ?.filter(
                      (f) =>
                        f.data_horas.split(" ")[0] ===
                        m?.data_horas.split(" ")[0]
                    )
                    ?.map((y) => y.id)
                    .indexOf(m.id) ===
                    a?.filter(
                      (f) =>
                        f.data_horas.split(" ")[0] ===
                        m?.data_horas.split(" ")[0]
                    ).length -
                      1 && <Data>{m?.data_horas.split(" ")[0]}</Data>}
                </React.Fragment>
              ))
            ) : (
              <>
                <h3>
                  Escolha um usuário para iniciar a conversa.
                  <br />
                  As mensagens não são criptografadas!
                </h3>
              </>
            )}
          </Messages>
          <InputContainer onSubmit={HandleSubmit}>
            <Input
              type="text"
              value={Mensagem}
              onChange={(e) => {
                setMensagem(e.target.value);
              }}
              required
              maxLength={999}
              disabled={!IdContatoSelecionado}
              placeholder="Mensagem"
            />
            <Button type="submit" disabled={!IdContatoSelecionado}>
              <SendIcon />
            </Button>
          </InputContainer>
        </ChatContainer>
      </Container>
    </Background>
  );
}
