import * as React from "react";
import { Container, Select, Label } from "./style";

const InputLabel = (props) => {
  return (
    <Container disabled={props?.disabled} className="container">
      <Select
        type={props?.type}
        value={props?.value}
        autoComplete="off"
        onChange={props?.onChange}
        required={props?.required}
        placeholder=""
        disabled={props?.disabled}
      >
        <option disabled value={typeof props?.value === "string" ? "" : 0}>
          {props?.defaultValue}
        </option>
        {props?.options?.map((o, i) => (
          <option value={props?.values[i]} key={i}>
            {o}
          </option>
        ))}
      </Select>
      <Label>{props?.label}</Label>
    </Container>
  );
};

export default InputLabel;
