import React, { useState, useEffect } from "react";
import { Background, Container, Table, AnexoIcon, Header, Button, EditIcon, RemoveIcon, ShowIcon, ScrollTable, ConfigIcon } from "./style";
import api from "../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import GoBack from "../../../components/GoBack";
import { TokenPerfil, userId } from "../../../services/TokenData";
import ModalEdit from "../../../components/Modal/Gestor/EditSolicitacao";
import { TruncateString } from "../../../services/TruncateString";
import Swal from "sweetalert2";
import { add, format, difference } from "../../../services/DateFormatter";
import { MdContactSupport } from "react-icons/md";
import styled from "styled-components";

export default function Solicitacoes() {
  const [Dados, setDados] = useState([]);
  const [DadosSolicitacao, setDadosSolicitacao] = useState([]);
  const [ShowModalEdit, setShowModalEdit] = useState(false);
  const [Ocorrencia, setOcorrencia] = useState(null);
  const [Reload, setReload] = useState(false);
  const Location = useLocation();
  const Navigate = useNavigate();
  const UserId = userId();
  const [Processo, setProcesso] = useState(null);
  const [Logado, setLogado] = useState("");
  useEffect(() => {
    if (TokenPerfil() === "OPERADOR") {
      api
        .get(`/processos/${Location?.state?.IdProcesso}/${"operador"}/solicitacoes`)
        .then((response) => {
          setDados(response.data.solicitacoes_processo);
        });
    } else {
      api
        .get(`/processos/${Location?.state?.IdProcesso}/${"gestor"}/solicitacoes`)
        .then((response) => {
          setDados(response.data.solicitacoes_processo);
        });
    }
    
  }, [Location?.state?.IdOcorrencia, Reload]);

  function AlertaCor(dados) {
    if (dados?.situacao === "Aprovada") {
      return "G"
    } if (dados?.situacao === "Reprovada") {
      return "R"
    } else {
      return ""
    }
  }
  return (
    <Background>
      <GoBack />
      <Container>
        <Header style={{ display: "flex", justifyContent: "center" }}>
          <h1>Solicitações de Ocorrências - Processo {Location?.state?.NumProcesso}</h1>
        </Header>
        <ScrollTable>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Desc. Ocorrência</th>
                <th>Solicitante</th>
                <th>Qtde. Dias</th>
                <th>Justificativa</th>
                <th>Data da Solicitação</th>
                <th>Situação</th>
                <th>Motivo</th>
              </tr>
            </thead>
            <tbody>
              {Dados?.map((d, i) => (
                <tr key={i} onClick={() => { setDadosSolicitacao(d); setShowModalEdit(true) }}>
                  <td>{i + 1}</td>
                  <td title={d?.descricao}>{d?.descricao || "-"}</td>
                  <td>{d?.solicitante}</td>
                  <td>{d?.quantidade_dias}</td>
                  <td style={{ textAlign: "left" }}>{d?.justificativa_solicitacao}</td>
                  <td>{d?.data_solicitacao}</td>
                  <td className={AlertaCor(d)}>{d?.situacao}</td>
                  <td style={{textAlign:"left"}}>{d?.motivo_situacao || "-"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollTable>

        <table className="legenda">
          <tbody>
            <th
              style={{
                backgroundColor: "var(--alerta-vermelho-color)",
                color: "var(--alerta-vermelho-color)",
              }}
            >
              ______
            </th>
            <td>Reprovado</td>
            <th
              style={{
                backgroundColor: "var(--pastel-green)",
                color: "var(--pastel-green)",
              }}
            >
              ______
            </th>
            <td>Aprovado</td>
          </tbody>
        </table>
      </Container>
      <ModalEdit
        Show={ShowModalEdit}
        setShow={setShowModalEdit}
        Reload={Reload}
        setReload={setReload}
        DadosSolicitacao={DadosSolicitacao}
        NumProcesso={Location?.state?.NumProcesso}
      />
    </Background>
  );
}
