/**
 * Formata strings de acordo com o número de caracteres desejados, 
 * @param {*} string texto a ser formatado
 * @param {*} charCount limite de caracteres
 * @returns texto formatado, com reticiências para indicar supressão
 */

export function TruncateString(string, charCount) {

    return string.length > charCount ?
    string.slice(0, Math.min(string.length, charCount)) + "..." :
    string;

};