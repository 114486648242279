import styled from "styled-components";
import { FiDownload } from "react-icons/fi";

export const Container = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;

  @media print {
    display: none;
  }
`;

export const DownloadIcon = styled(FiDownload)`
  font-size: 50px;
  cursor: pointer;
  color: var(--main-color);
  transition: transform 0.2s;

  &:hover {
    transform: translateY(5px);
  }
`;
