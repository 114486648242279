import { FaRegUserCircle } from "react-icons/fa";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: var(--secondary-font-color);
  gap: 10px;
  font-size: 20px;
  width:auto;
  max-width:200px;
  &:hover {
    cursor: pointer;
  }
    .link{
      text-decoration:none;
      width: 200px;
      display: flex;
      align-items: center;
      color: var(--secondary-font-color);
      gap: 20px;
      font-size: 20px;
        
    }
      &:after {
          content: "";
          top:  100%;
          left: 79%;
          right: 100%;
          width: 11%;
          height: 5px;
          position: absolute;
          transition: background-color 0.3s;
        }

        &:hover,
        &.active {
        &:after {
           background-color: var(--main-color);
          }
        }
`;

export const BackgroundClose = styled.div`
  display: ${(props) => !props.Show && "none"};
  z-index: -1;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: transparent;
  position: fixed;
  top: 60px;
  left: 0;
`;

export const NotificationContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 2px 10px;
  border-bottom: 2px solid var(--table-border-color);
  font-weight: ${({ seen }) => (!!seen ? "normal" : "bold")};
  color: ${({ seen }) => !!seen || "var(--main-color)"};

  &:hover {
    background-color: var(--first-auxiliary-color);

    span {
      background-color: var(--first-auxiliary-color);
    }
  }
`;

export const Title = styled.h1`
  display: ${(props) => !props.Empty && "none"};
`;


export const IconPerfil = styled(FaRegUserCircle)`
  font-size: 30px;
  color: var(--secondary-font-color);
`;

export const Navlink = styled(NavLink)`
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--secondary-font-color);
  padding: 0 10px;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
  font-size: 15px;
  border: 2px solid blue;

  &:after {
    content: "";
    top: 100%;
    left: 0;
    width: 100%;
    height: 5px;
    position: absolute;
    transition: background-color 0.3s;
  }

  &:hover,
  &.active {
    &:after {
      background-color: var(--main-color);
    }
  }
`;