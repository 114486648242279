import React from "react";
import { Container, ButtonLeft, ButtonRight, Counter } from "./style";
import { TiChevronLeft, TiChevronRight } from "react-icons/ti";

const Pagination = ({ Page, setPage, Limit, Count }) => {
  const PageLimit = Math.ceil(Count / Limit);

  return (
    <Container className="pagination">
      <ButtonLeft className="pagination"
        title="Página anterior"
        disabled={Page === 0}
        onClick={() => setPage(Page - 1)}
      >
        <TiChevronLeft />
      </ButtonLeft>
      <Counter>
        {Page + 1} de {PageLimit}
      </Counter>
      <ButtonRight className="pagination"
        title="Próxima página"
        disabled={Page + 1 === PageLimit}
        onClick={() => setPage(Page + 1)}
      >
        <TiChevronRight />
      </ButtonRight>
    </Container>
  );
};

export default Pagination;
