import React, { useEffect } from "react";
import Routes from "../src/routes";
import GlobalStyle from "./global";

export default function App() {
  useEffect(() => {
    changeTheme();
    console.log(
      "  ______ _           _____             \n |  ____(_)         |  __ \\            \n | |__   _  ___  ___| |  | | _____   __\n |  __| | |/ _ \\/ __| |  | |/ _ \\ \\ / /\n | |    | |  __/ (__| |__| |  __/\\ V / \n |_|    |_|\\___|\\___|_____/ \\___| \\_/  \n\n\n https://fiec.com.br"
    );
  }, []);

  async function changeTheme() {
    if (localStorage.getItem("theme") === "dark") {
      document.body.classList.add("dark-theme");
    } else if (localStorage.getItem("theme") === "light") {
      document.body.classList.remove("dark-theme");
    } else {
      localStorage.setItem("theme", "light");
    }
  }

  window.onstorage = () => {
    changeTheme();
  };

  return (
    <>
      <Routes />
      <GlobalStyle />
    </>
  );
}
