import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Background,
  Container,
  Table,
  ShowIcon,
  Filters,
  ScrollTable,
} from "./style";
import api from "../../../services/api";
import InputLabel from "../../../components/InputLabel";
import Pagination from "../../../components/Pagination";
import { userId } from "../../../services/TokenData";
import { add, format, difference } from "../../../services/DateFormatter";
import { sse } from "../../../services/sse";
import { TruncateString } from "../../../services/TruncateString";

export default function Dashboard() {
  const [Dados, setDados] = useState([]);
  const [Search, setSearch] = useState("");
  const [DataInicio, setDataInicio] = useState("");
  const [DataFim, setDataFim] = useState("");
  const Navigate = useNavigate();
  const [Page, setPage] = useState(0);
  const Limit = 8;

  const Request = useCallback(() => {
    api
      .get(
        `/processos/operador/${userId()}?assunto=${Search}&data_inicio=${DataInicio}&data_fim=${DataFim}&page=${Page}&limit=${Limit}`
      )
      .then((response) => {
        setDados(response.data.processos);
      });
  }, [Page, Search, DataInicio, DataFim]);

  useEffect(() => {
    Request();
  }, [Request]);

  sse.addEventListener("message", (e) => {
    Request();
  });

  function AlertaCor(dados) {
    if (dados?.situacao === "Concluído" || dados?.situacao === "Cancelado") {
      return;
    }

    const data_prevista = format({
      DataInicial: add({
        DataInicial: dados?.data_inicio,
        Valor: dados?.dias_previstos + 1,
        FormatoValor: "D",
      }),
      Formato: "DD/MM/YYYY",
    });

    const diferenca = difference({
      DataInicial_1: Date(),
      DataInicial_2: data_prevista,
    });

    if (
      new Date(format({ DataInicial: data_prevista, Formato: "YYYY/MM/DD" })) <
      new Date()
    ) {
      return "V";
    }

    return diferenca <= dados?.dias_para_vermelho
      ? "V"
      : diferenca <= dados?.dias_para_amarelo
        ? "A"
        : "";
  }

  return (
    <Background>
      <Container>
        <Filters>
          <InputLabel
            type="search"
            value={Search}
            onChange={(e) => setSearch(e.target.value)}
            label="Buscar por assunto"
          />
          <InputLabel
            type="date"
            value={DataInicio}
            onChange={(e) => setDataInicio(e.target.value)}
            label="Data Início"
          />
          <InputLabel
            type="date"
            value={DataFim}
            onChange={(e) => setDataFim(e.target.value)}
            label="Data Fim"
          />
        </Filters>
        <ScrollTable>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Número</th>
                <th>Assunto</th>
                <th>Entrada</th>
                <th>Responsável</th>
                <th>Situação</th>
                <th>Data Início</th>
                <th>Dias Previstos</th>
                <th>Data Prevista</th>
                <th>Dias Restantes</th>
                <th>Data Conclusão</th>
                <th>Duração</th>
                <th>Data Espera</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Dados?.map((d, i) => (
                <tr key={i}>
                  <td>{d?.id}</td>
                  <td className={AlertaCor(d)}>{d?.numero}</td>
                  <td>{TruncateString(d?.assunto, 20)}</td>
                  <td>
                    {format({
                      DataInicial: d?.data_entrada,
                      Formato: "DD/MM/YYYY",
                    }) || "-"}
                  </td>
                  <td>{d?.responsavel_nome}</td>
                  <td>{d?.situacao}</td>
                  <td>
                    {format({
                      DataInicial: d?.data_inicio,
                      Formato: "DD/MM/YYYY",
                    }) || "-"}
                  </td>
                  <td>{d?.dias_previstos}</td>
                  <td>
                    {format({
                      DataInicial: add({
                        DataInicial: d?.data_inicio,
                        Valor: d?.dias_previstos + 1,
                        FormatoValor: "D",
                      }),
                      Formato: "DD/MM/YYYY",
                    })}
                  </td>
                  <td>
                    {difference({
                      DataInicial_2: format({
                        DataInicial: add({
                          DataInicial: d?.data_inicio,
                          Valor: d?.dias_previstos + 1,
                          FormatoValor: "D",
                        }),
                        Formato: "YYYY-MM-DD",
                      }),
                      DataInicial_1: Date(),
                    }) || "0"}
                  </td>
                  <td>
                    {format({
                      DataInicial: d?.data_conclusao,
                      Formato: "DD/MM/YYYY",
                    }) || "-"}
                  </td>
                  <td>
                    {difference({
                      DataInicial_1: d?.data_inicio,
                      DataInicial_2: d?.data_conclusao,
                    }) || "-"}
                  </td>
                  <td>
                    {format({
                      DataInicial: d?.data_espera,
                      Formato: "DD/MM/YYYY",
                    }) || "-"}
                  </td>
                  <td>
                    <ShowIcon
                      title="Ver Processo"
                      onClick={() =>
                        Navigate("/operador/processo", {
                          state: {
                            IdProcesso: d?.id,
                            IdResponsavel: d?.responsavel,
                            NumProcesso: d?.numero
                          },
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollTable>
        <Pagination
          Page={Page}
          setPage={setPage}
          Limit={Limit}
          Count={Dados[0]?.count}
        />
      </Container>
    </Background>
  );
}
