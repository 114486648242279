import { useState, useEffect, useCallback } from "react";
import { Background, MenuButton, Container, Button } from "./style";
import { TokenPerfil } from "../../services/TokenData";
import Texts from "../../assets/json/texts.json";
import Routes from "../../assets/json/routes.json";
import { useLocation, useNavigate } from "react-router-dom";
import { RiArrowGoBackLine } from "react-icons/ri";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsVectorPen } from "react-icons/bs";
import { accessUser } from "../../services/TokenData";
import { Swal } from "../../services/SwalTemplates";
import api from "../../services/api";
import SignPad from "../Modal/SingPad";

export default function FloatingMenu() {
  const [Open, setOpen] = useState(false);
  const [Buttons, setButtons] = useState([]);
  const [Show, setShow] = useState(false);
  const [ShowSignPad, setShowSignPad] = useState(false);
  const Location = useLocation();
  const Navigate = useNavigate();
  const Token = localStorage.getItem("token");

  const ChangePerfil = useCallback(() => {
    switch (TokenPerfil()) {
      case "GESTOR":
        setButtons(Texts.STATIC_FLOATING_MENU_BUTTONS.GESTOR);
        break;
      case "OPERADOR":
        setButtons(Texts.STATIC_FLOATING_MENU_BUTTONS.OPERADOR);
        break;

      case "DEV":
        setButtons(Texts.STATIC_FLOATING_MENU_BUTTONS.DEV);
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    setOpen(false);
  }, [Location.pathname]);

  useEffect(() => {
    ChangePerfil();
    setShow(Routes.includes(window.location.pathname));
  }, [Location.pathname, ChangePerfil]);

  window.onstorage = () => {
    ChangePerfil();
  };

  async function returnAccessUser() {
    const data = { accessUser: false };
    try {
      await api.patch(`/accessuser`, { data }).then((response) => {
        if (response.data.error)
          return Swal.fire({
            title: "Atenção!",
            text: "Não foi possível acessar!",
            icon: "warning",
          });
        localStorage.setItem("token", response.data.token);
        Navigate("/dev/usuarios");
      });
    } catch (err) {
      Swal.fire({
        title: "Atenção!",
        text: "Não foi possível acessar!",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }
  }

  function IconRender(tipo) {
    switch (tipo) {
      case "RETURN_ACCESS_USER":
        if (accessUser()) return <RiArrowGoBackLine />;
        return;

      case "DOCUMENTATION":
        return <IoDocumentTextOutline />;

      case "SIGN":
        return <BsVectorPen />;

      default:
        return <p>?</p>;
    }
  }

  function HandleButtonClick(tipo) {
    switch (tipo) {
      case "RETURN_ACCESS_USER":
        if (accessUser()) returnAccessUser();
        break;

      case "DOCUMENTATION":
        if (TokenPerfil() === "GESTOR") {
          window.open(
            window.location.href.includes("localhost")
              ? `${Texts.STATIC_URL_DEV}/manualgestor?auth=Bearer ${Token}`
              : `${Texts.STATIC_URL_PROD}/manualgestor?auth=Bearer ${Token}`
          );
        } else if (TokenPerfil() === "OPERADOR") {
          window.open(
            window.location.href.includes("localhost")
              ? `${Texts.STATIC_URL_DEV}/manualoperador?auth=Bearer ${Token}`
              : `${Texts.STATIC_URL_PROD}/manualoperador?auth=Bearer ${Token}`
          );
        }
        break;

      case "SIGN":
        setShowSignPad(!ShowSignPad);
        break;

      default:
        break;
    }

    setOpen(false);
  }

  return (
    <Background show={Show && Buttons?.length}>
      <Container>
        {Buttons?.map((b, i) => (
          <Button
            key={i}
            open={Open}
            index={i + 1}
            hidden={!IconRender(b.TIPO)}
            onClick={() => HandleButtonClick(b.TIPO)}
            title={b?.TITLE}
          >
            {IconRender(b.TIPO)}
          </Button>
        ))}
        <MenuButton open={Open} onClick={() => setOpen(!Open)} title="Menu">
          <div>
            <div />
          </div>
          <div />
          <div>
            <div />
          </div>
          <div />
          <div />
          <div />
          <div>
            <div />
          </div>
          <div />
          <div>
            <div />
          </div>
        </MenuButton>
      </Container>
      <SignPad Show={ShowSignPad} SetShow={setShowSignPad} />
    </Background>
  );
}
