import styled from "styled-components";

export const Background = styled.div`
  min-width: 100vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
`;

export const Title = styled.h1`
  color: var(--primary-font-color);
`;

export const Button = styled.button`
  border: none;
  height: 40px;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
`;
