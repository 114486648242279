import styled from "styled-components";

export const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  width: fit-content;
  align-items: center;
  color: var(--primary-font-color);
  transition: transform 0.5s;
  padding: 0 10px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 0.8em;
  pointer-events: none;
  letter-spacing: 0.2em;
  height: fit-content;
  color: var(--primary-bg-color);
  background-color: var(--second-auxiliary-color);
  transform: translate(10px, -50%);
  max-width: 90%;
`;

export const Select = styled.select`
  width: 100%;
  height: 50px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid var(--second-auxiliary-color);
  outline: none;
  color: var(--primary-font-color);
  padding: 10px;
  font-size: 1em;
  margin-bottom: 1%;
`;

export const Container = styled.div`
  position: relative;
  max-width: 85%;
  width: 100%;
  opacity: ${(props) => props.disabled && "0.5"};
`;
