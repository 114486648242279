import styled from "styled-components";
import { IoArrowBack } from "react-icons/io5";

export const Container = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;

  @media print {
    display: none;
  }
`;

export const BackIcon = styled(IoArrowBack)`
  font-size: 50px;
  cursor: pointer;
  color: var(--main-color);
  transition: transform 0.2s;

  &:hover {
    transform: translateX(-5px);
  }
`;
