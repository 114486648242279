import React, { useState, useEffect } from "react";
import {
  Background,
  Container,
  Table,
  RemoveIcon,
  EditIcon,
  AnexoIcon,
  ShowIcon,
  PdfIcon,
  Filters,
  Button,
  ScrollTable,
  Title,
} from "./style";
import api from "../../../services/api";
import InputLabel from "../../../components/InputLabel";
import ModalRegister from "../../../components/Modal/Gestor/RegisterProcesso";
import ModalEdit from "../../../components/Modal/Gestor/EditProcesso";
import { useNavigate } from "react-router-dom";
import { Swal } from "../../../services/SwalTemplates";
import { add, format, difference } from "../../../services/DateFormatter";
import { TruncateString } from "../../../services/TruncateString";
import Pagination from "../../../components/Pagination";
import { userId } from "../../../services/TokenData";

export default function Dashboard() {
  const [Dados, setDados] = useState([]);
  const [Search, setSearch] = useState("");
  const [DataInicio, setDataInicio] = useState("");
  const [DataFim, setDataFim] = useState("");
  const [ShowModalRegister, setShowModalRegister] = useState(false);
  const [ShowModalEdit, setShowModalEdit] = useState(false);
  const [id_usuario_acao, setId_usuario_acao] = useState(userId());
  const [DadosEdit, setDadosEdit] = useState("");
  const [Page, setPage] = useState(0);
  const [Reload, setReload] = useState(false);
  const Navigate = useNavigate();
  const Limit = 8;

  useEffect(() => {
    api
      .get(
        `/processos?assunto=${Search}&data_inicio=${DataInicio}&data_fim=${DataFim}&page=${Page}&limit=${Limit}`
      )
      .then((response) => {
        setDados(response?.data?.processos);
      });
  }, [DataInicio, DataFim, Search, Reload, Page]);

  function deletaProcesso(id, processo, id_usuario) {
    Swal.fire({
      title: `Deseja excluir o processo ${processo}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--pale-magenta-pink)",
      cancelButtonColor: "var(--pale-cerulean-color)",
      confirmButtonText: "DELETAR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/processos/${id}/${id_usuario}`)
          .then((res) => {
            Swal.fire(`Processo deletado.`, "", "success");
            setReload(!Reload);
          });
      }
    });
  }

  function AlertaCor(dados) {
    if (dados?.situacao === "Concluído" || dados?.situacao === "Cancelado") {
      return;
    }

    const data_prevista = format({
      DataInicial: add({
        DataInicial: dados?.data_inicio,
        Valor: dados?.dias_previstos + 1,
        FormatoValor: "D",
      }),
      Formato: "DD/MM/YYYY",
    });

    const diferenca = difference({
      DataInicial_1: Date(),
      DataInicial_2: data_prevista,
    });

    if (
      new Date(format({ DataInicial: data_prevista, Formato: "YYYY/MM/DD" })) <
      new Date()
    ) {
      return "V";
    }

    return diferenca <= dados?.dias_para_vermelho
      ? "V"
      : diferenca <= dados?.dias_para_amarelo
      ? "A"
      : "";
  }

  return (
    <Background>
      <Container>
        <Title>
          <h1>Lista de Processos</h1>
        </Title>
        <Filters>
          <Button onClick={() => setShowModalRegister(true)}>Novo</Button>
          <InputLabel
            type="search"
            value={Search}
            onChange={(e) => {
              setSearch(e.target.value);
              setPage(0);
            }}
            label="Buscar"
          />
          <InputLabel
            type="date"
            value={DataInicio}
            onChange={(e) => setDataInicio(e.target.value)}
            label="Data Início"
          />
          <InputLabel
            type="date"
            value={DataFim}
            onChange={(e) => setDataFim(e.target.value)}
            label="Data Fim"
          />
        </Filters>
        <ScrollTable>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Número</th>
                <th></th>
                <th>Assunto</th>
                <th>Entrada</th>
                <th>Responsável</th>
                <th>Situação</th>
                <th>Data Início</th>
                <th>Dias Previstos</th>
                <th>Data Prevista</th>
                <th>Dias Restantes</th>
                <th>Data Conclusão</th>
                <th>Duração</th>
                <th>Data Espera</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Dados?.map((d, i) => (
                <tr key={i}>
                  <td>{d?.id}</td>
                  <td className={AlertaCor(d)}> {d?.numero}</td>
                  <td>
                    {d?.anexos === 0 || (
                      <AnexoIcon
                        title={`${d?.anexos} ${
                          d?.anexos === 1 ? "anexo" : "anexos"
                        }`}
                      />
                    )}
                  </td>
                  <td title={d?.assunto}>{TruncateString(d?.assunto, 20)}</td>
                  <td>
                    {format({
                      DataInicial: d?.data_entrada,
                      Formato: "DD/MM/YYYY",
                    }) || "-"}
                  </td>
                  <td title={d?.responsavel_nome}>
                    {TruncateString(d?.responsavel_nome, 10)}
                  </td>
                  <td>{d?.situacao}</td>
                  <td>
                    {format({
                      DataInicial: d?.data_inicio,
                      Formato: "DD/MM/YYYY",
                    }) || "-"}
                  </td>
                  <td>{d?.dias_previstos}</td>
                  <td>
                    {format({
                      DataInicial: add({
                        DataInicial: d?.data_inicio,
                        Valor: d?.dias_previstos + 1,
                        FormatoValor: "D",
                      }),
                      Formato: "DD/MM/YYYY",
                    })}
                  </td>
                  <td>
                    {difference({
                      DataInicial_2: format({
                        DataInicial: add({
                          DataInicial: d?.data_inicio,
                          Valor: d?.dias_previstos + 1,
                          FormatoValor: "D",
                        }),
                        Formato: "YYYY-MM-DD",
                      }),
                      DataInicial_1: Date(),
                    }) || "0"}
                  </td>
                  <td>
                    {format({
                      DataInicial: d?.data_conclusao,
                      Formato: "DD/MM/YYYY",
                    }) || "-"}
                  </td>
                  <td>
                    {difference({
                      DataInicial_1: d?.data_inicio,
                      DataInicial_2: d?.data_conclusao,
                    }) || "-"}
                  </td>
                  <td>
                    {format({
                      DataInicial: d?.data_espera,
                      Formato: "DD/MM/YYYY",
                    }) || "-"}
                  </td>
                  <td>
                    <ShowIcon
                      title="Ver Processo"
                      onClick={() =>
                        Navigate("/gestor/processo", {
                          state: { 
                            IdOcorrencia: d?.id,
                            IdResponsavel: d?.responsavel,
                            NumProcesso: d?.numero
                           },
                        })
                      }
                    />
                  </td>
                  <td>
                    <PdfIcon
                      title="PDF"
                      onClick={() =>
                        Navigate("/ocorrenciapdf", {
                          state: { Id_processo: d?.id },
                        })
                      }
                    />
                  </td>
                  <td>
                    <EditIcon
                      title="Editar"
                      onClick={() => {
                        setDadosEdit(d);
                        setShowModalEdit(true);
                      }}
                    />
                  </td>
                  <td>
                    <RemoveIcon
                      title="Excluir"
                      onClick={() => {
                        deletaProcesso(d?.id, d?.numero, userId());
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollTable>
        <Pagination
          Page={Page}
          setPage={setPage}
          Limit={Limit}
          Count={Dados[0]?.count}
        />
      </Container>
      <ModalRegister
        Show={ShowModalRegister}
        setShow={setShowModalRegister}
        setReload={setReload}
        Reload={Reload}
      />
      <ModalEdit
        Show={ShowModalEdit}
        setShow={setShowModalEdit}
        Dados={DadosEdit}
        setReload={setReload}
        Reload={Reload}
      />
    </Background>
  );
}
