import React from 'react'
import { Container, Label, Textarea } from './style'

const TextareaLabel = (props) => {
  return (
    <Container disabled={props?.disabled} className="container">
      <Textarea
        type={props?.type}
        value={props?.value}
        autoComplete="off"
        onChange={props?.onChange}
        required={props?.required}
        placeholder={props?.placeholder}
        disabled={props?.disabled}
        min={props?.min}
        max={props?.max}
        onClick={props?.onClick}
        onFocus={props?.onFocus}
        id={props?.id}
        maxLength={props?.maxLength}
      />
      <Label htmlFor={props?.id}>{props?.label}</Label>
    </Container>
  )
}

export default TextareaLabel