import React, { useState, useEffect } from "react";
import { Background, Form, Title, Button, CloseIcon } from "./style";
import InputLabel from "../../InputLabel";
import SelectLabel from "../../SelectLabel";
import Texts from "../../../assets/json/texts.json";
import { Swal } from "../../../services/SwalTemplates";
import api from "../../../services/api";
import { difference } from "../../../services/DateFormatter";
import { userId } from "../../../services/TokenData";

export default function EditProcessoOcorrencia({
  Show,
  setShow,
  Reload,
  setReload,
  Dados,
  IdResponsavel,
}) {
  const [NovaSituacao, setNovaSituacao] = useState("");
  const [Prazo, setPrazo] = useState(0);
  const [RealodData, setRealodData] = useState(false);

  async function HandleSubmit(e) {
    e.preventDefault();

   if(NovaSituacao !== ""){
    const data1 = {
      data_conclusao: NovaSituacao === "Concluído" ? new Date() : null,
      data_espera: NovaSituacao === "Aguardando" ? new Date() : null,
      situacao: NovaSituacao === "" ? Dados.situacao: NovaSituacao,
      id_remetente: Dados.responsavel_id,
      id_destinatario: Dados.id_gestor,
      id_usuario_acao: userId()
    };
    api
    .patch(`/processos/${Dados?.id}/situacao`, { data: data1 })
    .then((response) => {
      Swal.fire({
        title: "Sucesso!",
        text: response?.data?.success,
        icon: "success",
        confirmButtonColor: "var(--main-color)",
      });
      setShow(false)
      setReload(!Reload)
    })
    .catch((e) => {
      console.log(e);
      Swal.fire({ title: "Atenção!", text: "Error", icon: "error" });
    });
   }
    
    
    if (Number(Prazo) !== 0){
      const data2 = {
        id_processo: Dados.id,
        id_ocorrencia: null,
        id_solicitante: Dados.responsavel_id,
        id_destinatario: Dados.id_gestor,
        quantidade_dias: Number(Prazo),
        justificativa_solicitacao: "Solicitação de alteração de prazo de processo.",
      }
      api
        .post(`/solicitacoes`, {data: data2})
        .then((response) => {
          Swal.fire({
            title: "Sucesso!",
            text: response?.data?.success,
            icon: "success",
            confirmButtonColor: "var(--main-color)",
          });
          setShow(false)
          setReload(!Reload)
        })
        .catch((e) => {
          console.log(e);
          Swal.fire({ title: "Atenção!", text: "Error", icon: "error" });
        })
    }

    
  }

  async function Close() {
    setNovaSituacao("");
    setPrazo(0);
    setShow(false);
  }
  
  return (
    <Background Show={Show}>
      <Form onSubmit={HandleSubmit}>
        <CloseIcon onClick={() => Close()} />
        <Title>Editar Processo</Title>
        <SelectLabel
          type="text"    
          onChange={(e) => setNovaSituacao(e.target.value)}
          label="Alterar situação do processo?"
          defaultValue={NovaSituacao}
          options={Texts?.STATIC_SITUACOES_PROCESSOS}
          values={Texts?.STATIC_SITUACOES_PROCESSOS}
        />
        <InputLabel
          type="number"
          value={Prazo}
          onChange={(e) => setPrazo(e.target.value)}
          label="Solicitar + prazo para o processo? (em dias)"
          required
        />
        <Button type="submit">Salvar</Button>
      </Form>
    </Background>
  );
}
