import { Background, Container, Title, Button } from "./style";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const Navigate = useNavigate();

  return (
    <Background>
      <Container>
        <Title>Página não encontrada!</Title>
        <Button onClick={() => Navigate("/")}>Voltar</Button>
      </Container>
    </Background>
  );
}
