import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import {
  isAuthenticatedGestor,
  isAuthenticatedOperador,
  isAuthenticatedDev,
  isAuthenticated,
} from "./auth";
import Navbar from "./components/Navbar";
import ColorMode from "./components/ColorMode";
import FloatingMenu from "./components/FloatingMenu";

/**
 * Rotas Públicas
 */
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";

/**
 * Rotas Privadas Comuns
 */
import Perfil from "./pages/Perfil";
import Chat from "./pages/Chat";

/**
 * Rotas de Gestor
 */
import GestorDashboard from "./pages/Gestor/Dashboard";
import GestorUsuarios from "./pages/Gestor/Usuarios";
import GestorAcessos from "./pages/Gestor/Acessos";
import GestorProcesso from "./pages/Gestor/Processo";
import GestorAnexos from "./pages/Gestor/Anexos";
import GestorPdfProcesso from "./pages/Gestor/PdfProcessoOcorrencias";
import GestorSolicitacoes from "./pages/Gestor/Solicitacoes";
import SolicitacoesProcessos from "./pages/Gestor/SolicitacoesProcesso/Index";

/**
 * Rotas de Operador
 */
import OperadorProcessos from "./pages/Operador/Processos";
import OperadorProcesso from "./pages/Operador/Processo";
import OperadorAnexos from "./pages/Operador/Anexos";
import OperadorMinhasSolicitacoes from "./pages/Operador/MinhasSolicitacoes";

/**
 * Rotas de Dev
 */
import DevUsuarios from "./pages/Dev/Usuarios";

export default function Rotas() {
  function PrivateRoute({ children }) {
    return isAuthenticated() ? children : <Navigate to="/" />;
  }

  function GestorRoute({ children }) {
    return isAuthenticatedGestor() || isAuthenticatedDev() ? (
      children
    ) : (
      <Navigate to="/" />
    );
  }

  function OperadorRoute({ children }) {
    return isAuthenticatedOperador() ||
      isAuthenticatedGestor() ||
      isAuthenticatedDev() ? (
      children
    ) : (
      <Navigate to="/" />
    );
  }

  function DevRoute({ children }) {
    return isAuthenticatedDev() ? children : <Navigate to="/" />;
  }

  return (
    <BrowserRouter>
      <Navbar />
      <ColorMode />
      <FloatingMenu />
      {/* <Navbar /> */}
      <Routes>
        {/* Rotas Públicas */}
        <Route path="/" element={<Login />} />

        {/* Rotas Privadas Comuns */}
        <Route
          path="/perfil"
          element={
            <PrivateRoute>
              <Perfil />
            </PrivateRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />

        {/* Rotas de Gestor */}
        <Route
          path="/gestor/dashboard"
          element={
            <GestorRoute>
              <GestorDashboard />
            </GestorRoute>
          }
        />
        <Route
          path="/gestor/usuarios"
          element={
            <GestorRoute>
              <GestorUsuarios />
            </GestorRoute>
          }
        />
        <Route
          path="/gestor/acessos"
          element={
            <GestorRoute>
              <GestorAcessos />
            </GestorRoute>
          }
        />
        <Route
          path="/gestor/processo"
          element={
            <GestorRoute>
              <GestorProcesso />
            </GestorRoute>
          }
        />
        <Route
          path="/gestor/anexos"
          element={
            <GestorRoute>
              <GestorAnexos />
            </GestorRoute>
          }
        />
        <Route
          path="/ocorrenciapdf"
          element={
            <GestorRoute>
              <GestorPdfProcesso />
            </GestorRoute>
          }
        />
        <Route
          path="/gestor/solicitacoes"
          element={
            <GestorRoute>
              <GestorSolicitacoes />
            </GestorRoute>
          }
        />
        <Route
          path="/gestor/solicitacoes_processos"
          element={
            <GestorRoute>
              <SolicitacoesProcessos />
            </GestorRoute>
          }
        />
        {/* Rotas de Operador */}
        <Route
          path="/operador/processos"
          element={
            <OperadorRoute>
              <OperadorProcessos />
            </OperadorRoute>
          }
        />
        <Route
          path="/operador/solicitacoes"
          element={
            <OperadorRoute>
              <GestorSolicitacoes />
            </OperadorRoute>
          }
        />
        <Route
          path="/operador/solicitacoes"
          element={
            <OperadorRoute>
              <GestorSolicitacoes />
            </OperadorRoute>
          }
        />
        <Route
          path="/operador/solicitacoes_processo"
          element={
            <OperadorRoute>
              <SolicitacoesProcessos />
            </OperadorRoute>
          }
        />
        <Route
          path="/operador/anexos"
          element={
            <OperadorRoute>
              <OperadorAnexos />
            </OperadorRoute>
          }
        />
        <Route
          path="/operador/processo"
          element={
            <OperadorRoute>
              <OperadorProcesso />
            </OperadorRoute>
          }
        />
        <Route
          path="/operador/minhas_solicitacoes"
          element={
            <OperadorRoute>
              <OperadorMinhasSolicitacoes />
            </OperadorRoute>
          }
        />

        {/* Rotas de Dev */}
        <Route
          path="/dev/usuarios"
          element={
            <DevRoute>
              <DevUsuarios />
            </DevRoute>
          }
        />

        {/* Não Encontrado */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
