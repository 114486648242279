import React, { useState, useEffect } from "react";
import {
  Background,
  Container,
  Table,
  AnexoIcon,
  Header,
  Button,
  EditIcon,
  RemoveIcon,
  ShowIcon,
  ScrollTable,
  ConfigIcon,
  ClockSolicitacao,
  ClockIcon,
} from "./style";
import api from "../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import GoBack from "../../../components/GoBack";
import ModalRegister from "../../../components/Modal/Gestor/RegisterOcorrencia";
import { userId } from "../../../services/TokenData";
import ModalEdit from "../../../components/Modal/EditOcorrencia";
import { TruncateString } from "../../../services/TruncateString";
import Swal from "sweetalert2";
import { add, format, difference } from "../../../services/DateFormatter";
import { MdContactSupport } from "react-icons/md";
import ModalEditProcess from "../../../components/Modal/EditarProcessoOcorrencia";
import styled from "styled-components";
import { LuCable } from "react-icons/lu";
import ModalTime from "../../../components/Modal/SolicitacaoTempo";

export default function Processo() {
  const [Dados, setDados] = useState([]);
  const [ShowModalRegister, setShowModalRegister] = useState(false);
  const [ShowModalEdit, setShowModalEdit] = useState(false);
  const [ShowModalClock, setShowModalClock] = useState(false);
  const [Ocorrencia, setOcorrencia] = useState(null);
  const [Reload, setReload] = useState(false);
  const Location = useLocation();
  const Navigate = useNavigate();
  const UserId = userId();
  const [ShowModalEditProcess, setShowModalEditProcess] = useState(false);
  const [Processo, setProcesso] = useState(null);

  useEffect(() => {
    api
      .get(`/processos/${Location?.state?.IdOcorrencia}/ocorrencias`)
      .then((response) => {
        setDados(response.data.processos);
      });
  }, [Location?.state?.IdOcorrencia, Reload]);
  function deletaOcorrencia(id_processo, seq_ocorrencia) {
    Swal.fire({
      title: `Deseja excluir a ocorrência ${seq_ocorrencia}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--pale-magenta-pink)",
      cancelButtonColor: "var(--pale-cerulean-color)",
      confirmButtonText: "DELETAR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/ocorrencias/${id_processo}/${seq_ocorrencia}`, {
            data: { id_usuario_acao: userId() },
          })
          .then((res) => {
            Swal.fire(`Ocorrencia deletada.`, "", "success");
            setReload(!Reload);
          });
      }
    });
  }

  function AlertaCor(dados) {
    if (dados?.situacao === "Concluído") {
      if (
        difference({
          DataInicial_1: dados?.data_conclusao,
          DataInicial_2: dados?.data_prevista,
        }) > 0 ||
        dados?.data_conclusao === dados?.data_prevista
      ) {
        return "G";
      } else {
        return "O";
      }
    }
    const dias_previstos = difference({
      DataInicial_1: format({
        DataInicial: dados?.data_emissao,
        Formato: "DD/MM/YYYY",
      }),
      DataInicial_2: dados?.data_prevista,
    });

    const diferenca = difference({
      DataInicial_1: Date(),
      DataInicial_2: dados?.data_prevista,
    });

    if (
      new Date(
        format({ DataInicial: dados?.data_prevista, Formato: "YYYY/MM/DD" })
      ) < new Date()
    ) {
      return "R";
    }

    return diferenca <= dias_previstos * 0.1
      ? "R"
      : diferenca <= dias_previstos * 0.2
        ? "Y"
        : "";
  }

  return (
    <Background>
      <GoBack />
      <Container>
        <Header>
          <Button onClick={() => setShowModalRegister(true)}>Novo</Button>
          <h1>Lista de Ocorrências</h1>
          {/* <Button onClick={() => Navigate("/gestor/solicitacoes", { state: { IdProcesso: Location?.state?.IdOcorrencia, NumProcesso: Location?.state?.NumProcesso } })}><ClockSolicitacao></ClockSolicitacao></Button> */}
          <div className="btnPrazo" onClick={() => Navigate("/gestor/solicitacoes", { state: { IdProcesso: Location?.state?.IdOcorrencia, NumProcesso: Location?.state?.NumProcesso } })}>
            <ClockSolicitacao title="Solicitações de Prorrogação de Prazo" />
          </div>
        </Header>
        <ScrollTable>
          <Table className="info">
            <tr>
              <th style={{ width: "14%" }}>Processo Nº : {Dados?.numero}</th>
              <th>Assunto: {Dados?.assunto}</th>
              <th style={{ width: "10%" }}>
                Data Prevista:{" "}
                {format({
                  DataInicial: add({
                    DataInicial: Dados?.data_inicio,
                    Valor: Dados?.dias_previstos + 1,
                    FormatoValor: "D",
                  }),
                  Formato: "DD/MM/YYYY",
                })}
              </th>
              <th style={{ width: "5%" }}>
                <ConfigIcon
                  onClick={() => {
                    setProcesso(Dados);
                    setShowModalEditProcess(true);
                  }}
                />
              </th>
            </tr>
          </Table>
        </ScrollTable>
        <ScrollTable>
          <Table>
            <thead style={{ position: "sticky", top: 0, backgroundColor: "var(--table-primary-color)", width: "100%", border: "2px solid white" }}>
              <tr>
                <th>Seq.</th>
                <th>Remetente</th>
                <th>Destinátario</th>
                <th>Descrição</th>
                <th>Data Inicial</th>
                <th>Data Prevista</th>
                <th>Data Conclusão</th>
                <th>Situação</th>
                <th>Dias Restantes</th>
                <th>Anexos</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Dados?.ocorrencias?.map((d, i) => (
                <tr key={i}>
                  <td>{d?.seq_ocorrencia}</td>
                  <td title={d?.nome_remetente}>
                    {TruncateString(d?.nome_remetente, 12)}
                  </td>
                  <td title={d?.nome_destinatario}>{TruncateString(d?.nome_destinatario, 20)}</td>
                  <td title={d?.descricao}>
                    {TruncateString(d?.descricao, 12)}
                  </td>
                  <td>{d?.data_emissao}</td>
                  <td>{d?.data_prevista}</td>
                  <td>{d?.data_conclusao || "-"}</td>
                  <td className={AlertaCor(d)}>{d?.situacao}</td>
                  <td className={AlertaCor(d)}>
                    {d?.situacao === "Concluído"
                      ? "-"
                      : difference({
                        DataInicial_1: Date(),
                        DataInicial_2: d?.data_prevista,
                      })}
                  </td>
                  <td>
                    {!d?.anexos || (
                      <AnexoIcon
                        title="Ver Anexos"
                        onClick={() =>
                          Navigate("/gestor/anexos", {
                            state: {
                              NumProcesso: Dados?.numero,
                              IdProcesso: Dados?.id,
                              Seq: d?.seq_ocorrencia,
                              Desc: d?.descricao,
                            },
                          })
                        }
                      />
                    )}
                  </td>
                  <td>
                    <EditIcon
                      title="Editar"
                      onClick={() => {
                        setOcorrencia(d);
                        setShowModalEdit(true);
                      }}
                    />
                  </td>
                  <td>
                    <ClockIcon
                      className={UserId === d?.id_destinatario ? "V" : UserId === Dados?.responsavel ? "V" : "I"}
                      title="Solicitar Prorrogação de Prazo?"
                      onClick={() => {
                        setOcorrencia(d);
                        setShowModalClock(true);
                      }}
                    />
                  </td>
                  <td>
                    <RemoveIcon
                      title="Excluir"
                      onClick={() => {
                        deletaOcorrencia(Dados?.id, d?.seq_ocorrencia);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollTable>

        <table className="legenda">
          <tbody>
            <th
              style={{
                backgroundColor: "var(--alerta-vermelho-color)",
                color: "var(--alerta-vermelho-color)",
              }}
            >
              ______
            </th>
            <td>Atenção</td>
            <th
              style={{
                backgroundColor: "var(--alerta-amarelo-color)",
                color: "var(--alerta-amarelo-color)",
              }}
            >
              ______
            </th>
            <td>Alerta</td>
            <th
              style={{
                backgroundColor: "var(--sandy-brown)",
                color: "var(--sandy-brown)",
              }}
            >
              ______
            </th>
            <td>Concluído com atraso</td>
            <th
              style={{
                backgroundColor: "var(--pastel-green)",
                color: "var(--pastel-green)",
              }}
            >
              ______
            </th>
            <td>Concluído no prazo</td>
          </tbody>
        </table>
      </Container>
      <ModalRegister
        Show={ShowModalRegister}
        setShow={setShowModalRegister}
        NumProcesso={Dados?.numero}
        IdProcesso={Location?.state?.IdOcorrencia}
        setReload={setReload}
        Dados={Dados}
        Reload={Reload}
      />
      <ModalEdit
        Show={ShowModalEdit}
        setShow={setShowModalEdit}
        Reload={Reload}
        setReload={setReload}
        DadosOcorrencia={Ocorrencia}
        DadosProcesso={Dados}
      />
      <ModalTime
        Show={ShowModalClock}
        setShow={setShowModalClock}
        Reload={Reload}
        setReload={setReload}
        DadosOcorrencia={Ocorrencia}
        DadosProcesso={Dados}
      />
      <ModalEditProcess
        Show={ShowModalEditProcess}
        Reload={Reload}
        setShow={setShowModalEditProcess}
        setReload={setReload}
        Dados={Dados}
        IdResponsavel={Location?.state?.IdResponsavel}
      />
    </Background>
  );
}
