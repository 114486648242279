import styled from "styled-components";
import { FaEye } from "react-icons/fa";

export const Background = styled.div`
  min-width: 99vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;

  @media (max-width: 1440px) {
    padding-top: 50px;
    padding-bottom: 0px;
  }
`;

export const Container = styled.div`
  height: fit-content;
  width: 95%;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1440px) {
    padding: 15px;
  }
`;

export const ScrollTable = styled.div`
  overflow-x: auto;
  align-self: flex-start;
  width: 100%;
  height: fit-content;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: var(--table-primary-color);
  border-radius: 5px;

  .A {
    background-color: var(--alerta-amarelo-color);
  }

  .V {
    background-color: var(--alerta-vermelho-color);
  }

  td,
  th {
    padding: 10px;
  }

  tbody {
    tr:hover {
      background-color: var(--table-secondary-color);
    }
  }

  tr {
    border: 1px solid var(--table-border-color);
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: 5px;
`;

export const Button = styled.button`
  border: none;
  height: 40px;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;
`;

export const ShowIcon = styled(FaEye)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;
