import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { sse } from "../../../services/sse";
import { userId } from "../../../services/TokenData";
import {
  Container,
  NotificationContainer,
  Title,
  BackgroundClose,
  Content,
} from "./style";
import {TokenPerfil} from "../../../services/TokenData"

const Alertas = ({ Show, setShow, NaoVizualizados, setNaoVizualizados }) => {
  const [Alertas, setAlertas] = useState(null);
  const [Reload, setReload] = useState(false);
  const Navigate = useNavigate();

  
  const VizualizarAlerta = (id) => {
    api.patch(`/alertas/visualizar/${id}`).then(() => {
      setReload(!Reload)
      setNaoVizualizados(NaoVizualizados-1)
    });
  };
  
  useEffect(() => {
    function Request() {
      api.get(`/alertas/${userId()}`).then((res) => {
        setAlertas(res.data.alertas);
      });
    };
    Request();
  }, [Reload]);

  sse.addEventListener("message", () => {
    setReload(!Reload)
  });

  return (
    <Container Show={Show}>
      <BackgroundClose onClick={(e) => setShow(false)} Show={Show} />
      <Content Empty={!Alertas?.length}>
        <Title Empty={!Alertas?.length}>Sem Notificações</Title>
        {Alertas?.map((d) => (
          <NotificationContainer
            key={d?.id}
            seen={d?.visualizada}
            onClick={() => {
              !!d?.visualizada || VizualizarAlerta(d?.id);
              if (TokenPerfil() === "GESTOR"){
                Navigate(`/gestor/processo`, {
                state: {
                  IdOcorrencia: d?.id_processo,
                  },
                });}
                else if (TokenPerfil() === "OPERADOR"){
                Navigate(`/operador/processo`, {
                state: {
                  IdProcesso: d?.id_processo,
                  },
                });}
            }}
          >
            <h3>{!!d?.seq_ocorrencia ? `Processo: ${d?.numero} Seq: ${d?.seq_ocorrencia || d?.id_processo}` : `Processo: ${d?.numero}`}</h3>
            <span>{!!d?.justificativa_solicitacao ? d?.justificativa_solicitacao : null}</span>
            <span>{!!d?.quantidade_dias ? `Dias solicitados: ${d?.quantidade_dias}` : !d?.seq_ocorrencia && !d?.id_solicitacao ? `A situação do processo foi alterada.` : `Foi atrubuída uma nova ocorrência.`}</span>
          </NotificationContainer>
        ))}
      </Content>
    </Container>
  );
};

export default Alertas;
