import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    :root{
        /**
        * Paleta escolhida: https://coolors.co/palette/3d5a80-98c1d9-e0fbfc-ee6c4d-293241
        * #3D5A80
        * #98C1D9
        * #E0FBFC
        * #FF99C8 (modificado)
        * #293241
        * #8CD87B
        */
        /** Nome das cores https://www.color-name.com */
        --pale-magenta-pink: #ed3237;
        --light-cyan-color: #F3FCFF;
        --pale-cerulean-color: #CEDEDC;
        --metallic-blue-color: #083D77;
        --gunmetal-color: #293241;
        --pastel-green: #8CD87B;
        --red-salsa: #f4324a;
        --sandy-brown: #fb9c5a;
        --maize: #fcc755;

        --pale-cerulean-50-alpha-color: #98C1D97F;
        --metallic-blue-50-alpha-color: #3D5A807F;

        --main-color: var(--pale-magenta-pink);
        --blur-bg-color: rgba(0, 0, 0, 0.5);

        --alerta-amarelo-color: #fcc755;
        --alerta-vermelho-color: #f4324a;

        --swal-confirm-button-color: #3085d6;
        --swal-cancel-button-color: #d33;

        --button-text-color: #F3F3F3;
    }

    *{
        margin: 0;
        padding: 0;
    }

    html {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    *, *:before, *:after {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit;
    }

    body {
        transition: background-color 0.5s, color 0.5s;
        background-color: var(--primary-bg-color);

        --primary-bg-color: var(--light-cyan-color);
        --secondary-bg-color: var(--pale-cerulean-color);
        --first-auxiliary-color: var(--metallic-blue-color);
        --second-auxiliary-color: var(--gunmetal-color);
        --primary-font-color: var(--gunmetal-color);
        --secondary-font-color: var(--light-cyan-color);
        --table-primary-color: var(--metallic-blue-color);
        --table-secondary-color: var(--pale-cerulean-50-alpha-color);
        --table-border-color: var(--pale-cerulean-50-alpha-color);
        --contato-font-color: var(--light-cyan-color);
        --chat-scroll-color: var(--metallic-blue-50-alpha-color);
        font-family: sans-serif;
    }
    
    body.dark-theme {
        --primary-bg-color: #121212;
        --secondary-bg-color: #003866;
        --first-auxiliary-color: #003866;
        --second-auxiliary-color: var(--light-cyan-color);
        --primary-font-color: #F3F3F3;
        --secondary-font-color: #F3F3F3;
        --table-primary-color: #121212;
        --table-secondary-color: var(--metallic-blue-50-alpha-color);
        --table-border-color: var(--metallic-blue-50-alpha-color);
        --contato-font-color: var(--gunmetal-color);
        --chat-scroll-color: var(--pale-cerulean-50-alpha-color);
        
        .pagination {
            background-color: #121212;
        }

        h1, th, td {
            color: #F3F3F3;
        }
    }
`;
