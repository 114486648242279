import React, { useState, useEffect } from "react";
import {
  ActiveNotificationsIcon,
  NoneNotificationsIcon,
  Container,
  Counter,
} from "./style";
import { sse } from "../../services/sse";
import api from "../../services/api";
import { userId } from "../../services/TokenData";
import ModalAlertas from "../Modal/Alertas";
import { useLocation } from "react-router-dom";
import NotificationIcon from "../../assets/images/icon_processos.png";

sse.addEventListener("message", (e) => {
  new Notification("Alerta", {
    body: e.data.slice(1, -1),
    icon: NotificationIcon,
  });
});

export default function Notifications({ setShow, Show }) {
  const [NaoVizualizados, setNaoVizualizados] = useState(0);
  const [Update, setUpdate] = useState(false);
  const UserId = userId();
  const Location = useLocation();

  sse.addEventListener("message", (e) => {
    setUpdate(!Update)
  });

  useEffect(() => {
    function Request() {
      api.get(`/alertas/quant/${UserId}`).then((res) => {
        setNaoVizualizados(res.data.nao_visualizadas);
      });
    };
    Request();
  }, [UserId, Update]);

  useEffect(() => {
    setShow(false);
  }, [Location.pathname, setShow]);

  return (
    <Container count={NaoVizualizados}>
      {NaoVizualizados === 0 ? (
        <>
          <ModalAlertas 
          Show={Show} 
          setShow={setShow} 
          NaoVizualizados={NaoVizualizados}
          setNaoVizualizados={setNaoVizualizados}
          />
          <NoneNotificationsIcon onClick={() => setShow(!Show)} />
        </>
      ) : (
        <>
          <ModalAlertas Show={Show} setShow={setShow} />
          <Counter>{NaoVizualizados > 99 ? "+99" : NaoVizualizados}</Counter>
          <ActiveNotificationsIcon onClick={() => setShow(!Show)} />
        </>
      )}
    </Container>
  );
}
