import React, { useState, useEffect } from "react";
import { Background, Form, Title, Button, CloseIcon } from "./style";
import InputLabel from "../../../InputLabel";
import SelectLabel from "../../../SelectLabel";
import Texts from "../../../../assets/json/texts.json";
import api from "../../../../services/api";
import { Swal } from "../../../../services/SwalTemplates";
import { userId } from "../../../../services/TokenData";
import TextareaLabel from "../../../TextareaLabel";
import FileInput from "../../../FileInput";
import { format } from "../../../../services/DateFormatter";

export default function RegisterOcorrencia({
  Show,
  setShow,
  NumProcesso,
  IdProcesso,
  Reload,
  setReload,
}) {
  const [Descricao, setDescricao] = useState("");
  const [NovaSituacao, setNovaSituacao] = useState("");
  const [Destinatarios, setDestinatarios] = useState(null);
  const [IdDestinatarios, setIdDestinatarios] = useState(null);
  const [IdDestinatario, setIdDestinatario] = useState(0);
  const [QuantDias, setQuantDias] = useState(0);
  const [ShowFileInput, setShowFileInput] = useState(false);
  const [Anexo, setAnexo] = useState(null);
  const [RealodData, setRealodData] = useState(false);
  const [DataPrevista, setDataPrevista] = useState(
    format({ DataInicial: new Date(), Formato: "YYYY-MM-DD" })
  );

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    async function Request() {
      api.get(`/destinatarios/${userId()}`).then((response) => {
        setDestinatarios([
          "Ninguém",
          ...response.data.usuarios.map((r) => r.nome),
        ]);
        setIdDestinatarios([null, ...response.data.usuarios.map((r) => r.id)]);
      });
    }
    Request();
  }, []);

  async function HandleSubmit(e) {
    e.preventDefault();

    const data = {
      num_processo: NumProcesso,
      id_processo: IdProcesso,
      descricao: Descricao,
      id_remetente: userId(),
      id_destinatario: isChecked ? IdDestinatario : userId(),
      data_prevista: !!DataPrevista ? DataPrevista : null,
    };

    api
      .post(`/ocorrencias`, { data })
      .then((response) => {
        if (!!Anexo) {
          const config = {
            headers: { "content-type": "multipart/form-data" },
          };
          Anexo.set("seq_ocorrencia", response?.data?.seq);

          api
            .post(`/anexos`, Anexo, config)
            .then((resAnexo) => {
              Swal.fire({
                title: "Sucesso!",
                text: response?.data?.success,
                icon: "success",
                confirmButtonColor: "var(--main-color)",
              });
              setReload(!Reload);
              if (data.id_destinatario) {
                api.post(`/mail_ocorrencia`, { data })
              }
            })
            .catch((e) => {
              Swal.fire({ title: "Atenção!", text: e, icon: "error" });
            });
        } else {
          Swal.fire({
            title: "Sucesso!",
            text: response?.data?.success,
            icon: "success",
            confirmButtonColor: "var(--main-color)",
          });
          if (data.id_destinatario) {
            api.post(`/mail_ocorrencia`, { data })
          }
        }
        Close();
        setReload(!Reload);
      })
      .catch((e) => {
        Swal.fire({ title: "Atenção!", text: "Error", icon: "error" });
      });
  }

  async function Close() {
    setDescricao("");
    setNovaSituacao("");
    setIdDestinatario(0);
    setQuantDias(0);
    setAnexo(null);
    setShow(false);
  }

  return (
    <Background Show={Show}>
      <Form onSubmit={HandleSubmit}>
        <CloseIcon onClick={() => Close()} />
        <Title>Nova Ocorrência</Title>
        <TextareaLabel
          value={Descricao}
          onChange={(e) => setDescricao(e.target.value)}
          label="Descrição"
          id={"ocorrencia-descricao"}
          required
        />
        <div className="encaminharOcorrencia">
          <input type="checkbox" className="checkEncaminhar" checked={isChecked} onChange={handleOnChange} />
          <label>Encaminhar Ocorrência?</label>
        </div>
        <SelectLabel
          label="Encaminhar ocorrência?"
          value={IdDestinatario}
          onChange={(e) => setIdDestinatario(e.target.value)}
          defaultValue="Selecione o usuário"
          options={Destinatarios}
          values={IdDestinatarios}
          disabled={!isChecked}
        />
        <InputLabel
          type="date"
          value={DataPrevista}
          onChange={(e) => setDataPrevista(e.target.value)}
          label="Data Prevista"
          required
        />
        <Button type="button" onClick={() => setShowFileInput(true)}>
          Anexar arquivo
        </Button>
        <FileInput
          setShow={setShowFileInput}
          Show={ShowFileInput}
          NumProcesso={NumProcesso}
          IdProcesso={IdProcesso}
          setAnexo={setAnexo}
          Origin={"Ocorrencia"}
          setRealodData={setRealodData}
        />
        <Button type="submit">Salvar</Button>
      </Form>
    </Background>
  );
}
