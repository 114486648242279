import jwt_decode from "jwt-decode";

export const TokenPerfil = () => {
  const token = localStorage.getItem("token");

  try {
    const decoded = jwt_decode(token);
    return decoded?.perfil;
  } catch (error) {
    localStorage.removeItem("token");
    return false;
  }
};

export const Nome = () => {
  const token = localStorage.getItem("token");

  try {
    const decoded = jwt_decode(token);
    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      localStorage.removeItem("token");
      return false;
    }
    if (decoded.perfil === "GESTOR") return true;
    else return false;
  } catch (error) {
    localStorage.removeItem("token");
    return false;
  }
};

export const userId = () => {
  const token = localStorage.getItem("token");

  try {
    const decoded = jwt_decode(token);
    return decoded?.sub.id;
  } catch (error) {
    localStorage.removeItem("token");
    return false;
  }
};

export const accessUser = () => {
  const token = localStorage.getItem("token");

  try {
    const decoded = jwt_decode(token);
    return decoded?.accessUser;
  } catch (error) {
    localStorage.removeItem("token");
    return false;
  }
};

export const Auth = () => {
  const token = localStorage.getItem("token");

  try {
    const decoded = jwt_decode(token);
    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      localStorage.removeItem("token");
      return false;
    }
    return decoded?.auth;
  } catch (error) {
    localStorage.removeItem("token");
    return false;
  }
};
