import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Background,
  Page,
  Subpage,
  Content,
  Title,
  Table,
  Breaker,
  Assinaturas,
  Images,
  Flex,
} from "./style";
import PdfDownload from "../../../components/PdfDownload";
import GoBack from "../../../components/GoBack";
import Prefeitura from "../../../assets/images/prefeitura.jpg";
import Fiec from "../../../assets/images/fiec.png";
import api from "../../../services/api";
import { Swal } from "../../../services/SwalTemplates";

export default function PdfProcesso() {
  const [Dados, setDados] = useState([]);
  const [datapdf, setDataPdf] = useState("");
  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    api
      .get(`/ocorrenciaspdf?id_processo=${location?.state?.Id_processo}`)
      .then((response) => {
        if (response.data.error)
          return Swal.fire({
            title: "Atenção!",
            text: response.data.error,
            icon: "warning",
          });
        setDados(response.data.ocorrencias_processo);
        setDataPdf(response.data.datapdf);
      })
      .catch(
        (error) => {
          console.error(error)
          Swal.fire({
            title: "Atenção!",
            text: "Não foi possível consultar!",
            icon: "warning",
          })
        });
  }, [location?.state?.Id_processo, location?.state.id_processo]);


  return (
    <Background>
      <GoBack />
      <PdfDownload />
      <Page>
        <Subpage>
          <Breaker ref={ref}>
            <Content>
              <Images>
                <img src={Fiec} alt="Erro" />
                <img src={Prefeitura} alt="Erro" />
              </Images>



              <Title>Processo: {Dados[0]?.numero}</Title>
              
              
              <Table>
                <tbody>

                  <tr>
                    <td colSpan="3"><b>Assunto:</b> {Dados[0]?.assunto}</td>
                    <td style={{ textAlign: "right" }}><b>Dias previstos: </b>{Dados[0]?.dias_previstos}</td>
                  </tr>
                  <tr>
                    <td><b>Responsavel: </b>{Dados[0]?.responsavel}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right" }}><b>Data Entrada:</b> {Dados[0]?.data_entrada}</td>
                  </tr>
                  <tr>
                    <td><b>Situação Atual:</b> {Dados[0]?.situacao}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right" }}><b>Data Inicio:</b> {Dados[0]?.data_inicio}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    {(!Dados[0]?.data_conclusao && !Dados[0]?.data_espera) || <td style={{ textAlign: "right" }}><b> {!!Dados[0]?.data_conclusao ? "Data conclusão: " : "Data espera: "}
                    </b>{!!Dados[0]?.data_conclusao ? Dados[0]?.data_conclusao : Dados[0]?.data_espera} </td>}
                  </tr>
                </tbody>
              </Table>
              <br />
              <div style={{ textAlign: "center", fontSize: "12px"}}>Data de emissão: {datapdf}</div>
              <hr />
              <h4>Ocorrências do processo</h4>
              <br />

              <Table>
                <thead>
                  <tr>
                    <th>SEQ</th>
                    <th>Descrição</th>
                    <th>data</th>
                    <th>Emissor</th>
                  </tr>
                </thead>
                <tbody>
                  {Dados?.filter((o) => o.tipo === "ocorrencia")?.map((d, i) => (
                    <tr key={i}>
                      <td>{d?.seq}</td>
                      <td>{d?.descricao}</td>
                      <td>{d?.data_emissao_ocorrencia}</td>
                      <td>{d?.emissor}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <br />

              {Dados?.filter((x) => x.titulo_documento !== null)?.length >= 0 &&
                <>
                  <hr />
                  <h4>Anexos do processos</h4>
                  <br />


                  <Table>
                    <thead>
                      <tr>
                        <th style={{ width: "12%" }}>Ocorrência</th>
                        <th>Anexo</th>
                        <th style={{ width: "40%" }}>Titulo</th>
                        <th>Nome do arquivo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Dados
                        ?.map((f) => f?.seq_ocorrencia)
                        ?.filter((v, i, a) => a?.indexOf(v) === i)
                        ?.map((da, i, array) => (
                          <React.Fragment key={i}>
                          {Dados.filter((fa) => fa.seq_ocorrencia === da && fa.tipo === "anexo")?.map((xa, i) => (

                            <tr key={i}>
                              <td>{xa?.seq_ocorrencia}</td>
                              <td>{xa?.seq_ocorrencia_ocorrencia}</td>
                              <td>{xa?.titulo_documento}</td>
                              <td>{xa?.nome_arquivo}</td>
                            </tr>

                            )
                          )}
                          <tr className="sem-borda">
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>

                          </tr>
                          </React.Fragment>
                        ))}
                    </tbody>

                  </Table>
 
                </>
              }
            </Content>
          </Breaker>
        </Subpage>
      </Page>
    </Background>
  );
}
