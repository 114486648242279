import Texts from "../assets/json/texts.json";
import { userId } from "./TokenData";

const UserId = userId();

export const sse = new EventSource(
  `${
    window.location.href.includes("localhost")
      ? Texts.STATIC_URL_DEV
      : Texts.STATIC_URL_PROD
  }/stream?id=${UserId}`
);
