import { Container, BackIcon } from "./style";
import { useNavigate } from "react-router-dom";

export default function GoBack() {
  const Navigate = useNavigate();

  return (
    <Container>
      <BackIcon onClick={() => Navigate(-1)} title="Voltar" />
    </Container>
  );
}
