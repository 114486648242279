import React, { useState } from "react";
import { Container, SunIcon, MoonIcon } from "./style";

export default function ColorMode() {
  const [Angle, setAngle] = useState(
    localStorage.getItem("theme") === "dark" ? 180 : 0
  );

  function ChangeColorMode() {
    if (localStorage.getItem("theme") === "dark") {
      localStorage.setItem("theme", "light");
      document.body.classList.remove("dark-theme");
    } else if (localStorage.getItem("theme") === "light") {
      localStorage.setItem("theme", "dark");
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
      localStorage.setItem("theme", "light");
    }
  }

  return (
    <Container
      Angle={Angle}
      onClick={() => {
        ChangeColorMode();
        setAngle(Angle + 180);
      }}
    >
      <div></div>
      <div>
        <SunIcon />
      </div>
      <div>
        <MoonIcon />
      </div>
      <div></div>
    </Container>
  );
}
