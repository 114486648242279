import styled from "styled-components";
import { FiExternalLink } from "react-icons/fi";
import { HiTrash } from "react-icons/hi";

export const Background = styled.div`
  min-width: 100vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 50px;
  position: relative;
    
  @media (max-width: 1440px) {
    padding-bottom: 1px;
  }
`;

export const Container = styled.div`
  height: fit-content;
  width: 95%;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
      
  @media (max-width: 1440px) {
    padding: 15px;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  flex-wrap: wrap;
  gap: 20px;

  table {
    th {
      text-align: right;
    }
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const Button = styled.button`
  border: none;
  height: 40px;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
`;

export const ScrollTable = styled.div`
  overflow-x: auto;
  align-self: flex-start;
  width: 100%;
  height: fit-content;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: var(--table-primary-color);
  border-radius: 5px;

  td,
  th {
    padding: 10px;
  }

  tbody {
    tr:hover {
      background-color: var(--table-secondary-color);
    }
  }

  tr {
    border: 1px solid var(--table-border-color);
  }

  .td-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`;

export const RemoveIcon = styled(HiTrash)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const ExternalIcon = styled(FiExternalLink)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;
