import jwt_decode from "jwt-decode";

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");

  try {
    const decoded = jwt_decode(token);
    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      localStorage.removeItem("token");
      return false;
    }
    return true;
  } catch (error) {
    localStorage.removeItem("token");
    return false;
  }
};

export const isAuthenticatedGestor = () => {
  const token = localStorage.getItem("token");

  try {
    const decoded = jwt_decode(token);
    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      localStorage.removeItem("token");
      return false;
    }
    if (decoded.perfil === "GESTOR") return true;
    else return false;
  } catch (error) {
    localStorage.removeItem("token");
    return false;
  }
};

export const isAuthenticatedOperador = () => {
  const token = localStorage.getItem("token");

  try {
    const decoded = jwt_decode(token);
    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      localStorage.removeItem("token");
      return false;
    }
    if (decoded.perfil === "OPERADOR") return true;
    else return false;
  } catch (error) {
    localStorage.removeItem("token");
    return false;
  }
};

export const isAuthenticatedDev = () => {
  const token = localStorage.getItem("token");

  try {
    const decoded = jwt_decode(token);
    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      localStorage.removeItem("token");
      return false;
    }
    if (decoded.perfil === "DEV") return true;
    else return false;
  } catch (error) {
    localStorage.removeItem("token");
    return false;
  }
};
